// address
.address {
	border-radius: $borderRadius;
	border: 1px solid $borderColor;
	padding: 15px;
	margin-bottom: 24px;
	position: relative;

	&.address-default {
		border-color: $primaryColor;
	}

	
}

.addresses {
	@include breakpoint(tablet) {
		.col-sm-6 {
			margin-bottom: 24px;
		}

		.btn-group {
			position: absolute;
			bottom: 20px;
			left: 20px;
			width: calc(100% -30px);
		}

		.address {
			margin-bottom: 0;
			padding: 20px 20px 50px 20px;
			height: 100%;
		}
	}
}

// Manufacturer
.manufacturer {
	border-top: 1px solid $borderColor;
	margin-bottom: 24px;
	padding-top: 24px;
}