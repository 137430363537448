//- Mixin: Fluid Type
///
/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size 
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution 
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution 
/// @example
///   h1 {
///     @include fluid-type(20px, 48px);
///   }

@mixin fluidFont($min-font-size: 13px, $max-font-size: 21px, $lower-range: 320px, $upper-range: 1000px) {
  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}
