.cta__wrapper {
	@include breakpoint(desktop) {
		margin-bottom: 24px;

		.row {
			display: flex;
		}
	}
}

.cta {
	border-radius: $borderRadius;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 100%;
	min-height: 150px;
	margin-bottom: 24px;
	position: relative;

	h4 {
		color: #fff;
		font-size: 22px;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.24);
		margin-bottom: 18px;

		span {
			font-weight: 400;
		}
	}

	@include breakpoint(desktop) {
		margin-bottom: 0;
	}
}

.cta--action {
	padding: 20px 48px 48px 20px;

	.action {
		background: $attention;
		width: 90px;
		height: 90px;
		border-radius: 50%;
		text-align: center;
		box-shadow: 0px 0px 10px rgba(0,0,0,0.24);
		line-height: 84px;
		color: #fff;
		font-size: 24px;
		font-weight: bold;
		transform: rotate(12deg);
		position: absolute;
		top: -4px;
		right: -4px;
	}

	.btn {
		position: absolute;
		bottom: 10px;
		right: 10px;

		.fa {
			margin-right: 0;
		}
	}

	@include breakpoint(tablet) {

		.action {
			display: block;
			right: 32px;
			top: 6px;
		}

		h4 {
			position: absolute;
			max-width: 70%;
			width: 100%;
			bottom: 10px;
			left: 20px;
		}

		.btn {
			bottom: 20px;
			right: 20px;
		}
	}
}

.cta--offer {
	text-align: center;
	padding: 20px;
}

.cta--reviews {
	border: 3px solid #4cb944;
	padding: 30px 0 30px 20px;

	.number {
		background-image: url('/catalog/view/theme/be/assets/images/kiyoh.png');
		background-size: 50px;
		display: inline-block;
		font-size: 16px;
		font-weight: bold;
		height: 50px;
		line-height: 50px;
		text-align: center;
		margin-right: 4px;
		width: 50px;
		vertical-align: middle;
	}

	.rating {
		display: inline-block;
		height: 24px;
		position: relative;
		vertical-align: middle;
		width: 150px;

		i {
			position: absolute;
			top: 0;
			left: 0;

			$stars: 5;
			@for $i from 1 to $stars {
				&:nth-child(#{$i + 1}) {
					left: $i * 30px;
				}
			}
		}
	}

	.rating--top,
	.rating--bottom {
		position: absolute;
		top: 0;
		font-size: 26px;
		left: 0;
		width: 150px;
		height: 24px;
		overflow: hidden;
	}

	.rating--top {
		color: #edb719;
		z-index: 1;
	}
	.rating--bottom {
		color: #fae8ba;
		z-index: 0;
	}

	a {
		color: $fontColor;
		display: block;
		font-weight: bold;
		margin-top: 12px;

		&:hover {
			color: $fontColor;
			text-decoration: underline;
		}
	}
}

