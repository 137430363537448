
.scw-cookie-message a {
	color: #fff;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}


.scw-cookie-btn {
	@extend .btn;
	@extend .btn-primary;
}

.scw-cookie-panel-toggle {
	border: none;
	padding: 8px;
	text-align: center;
	width: 40px;

	&:before {
		font-size: 16px;
	}

	@include icon(before, 'f063');

	img { display: none; }

	&:hover {
		color: $secondaryColor;
	}

	.scw-cookie-slide-out & {
		@include icon(before, 'f062');
	}
}

.scw-cookie-toggle {
	border: 1px solid rgba(255,255,255,0.4);
	position: relative;
	padding-right: 80px;
	width: 100%;

	.scw-cookie-name {
		width: 100%;
	}

	p {
		margin-bottom: 0;
		padding-left: 15px;
	}    

	.scw-cookie-switch {
		position: absolute;
		top: 50%;
		width: 40px;
		transform: translateY(-50%);
		right: 20px;
	}

	&:nth-child(even) { margin-right: 0; }
	&:nth-child(odd) { margin-left: 0; }
}

.scw-cookie-switch {
	background: $grayBg;
	&.checked {
		background: $secondaryColor;
	}
}