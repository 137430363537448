// Open sans
@mixin baseFont($type: 400) {
	font-family: 'Open Sans', sans-serif;
	font-weight: $type;
	font-style: normal;
}

//https://fontawesome.com/v4.7.0/icons/#new
// Icons

// Map icon names to font unicode characters
// @include font-face(be, '../fonts/be', $file-formats: eot svg ttf woff);

$iconFont: 'FontAwesome';
$icons: ();