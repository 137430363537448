.category {
	border-radius: $borderRadius;
	border: 1px solid $borderColor;
	margin-bottom: 24px;
	padding-bottom: 30px;
	position: relative;

	&:hover {
		.category__title {
			background: $primaryColorHover;
		}
	}

	img {
		border-radius: $borderRadius $borderRadius 0 0;
		border-bottom: none;
		display: block;
		padding: 8px 15px;
		width: 100%;
	}

	&__title {
		@include baseFont(700);
		background: $primaryColor;
		border-radius: 0 0 $borderRadius $borderRadius;
		bottom: 0;
		color: #fff;
		left: 0;
		font-size: 11px;
		padding: 8px 12px;
		position: absolute;
		text-align: center;
		transition: background $transitionTime;
		width: 100%;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@include breakpoint(tablet) {
		padding-bottom: 40px;
		
		&__title {
			font-size: 14px;
			padding: 12px;
		}
	}
}

.categories .col-xs-6 {
	margin-top: 0;

	&:nth-child(odd) {
		padding-right: 4px;
	}
	&:nth-child(even) {
		padding-left: 4px;
	}

	@include breakpoint(tablet) {
		&:nth-child(odd) {
			padding-right: 15px;
		}
		&:nth-child(even) {
			padding-left: 15px;
		}
	}
}