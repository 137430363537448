.navigation {
	.container {
		position: relative;
	}

	.navbar {
		margin-bottom: 0;
	}

	.mn-link.active>a {
		color: $primaryColor;
	}

	.top-links {
		@include breakpoint(desktop) {
			font-size: 12px;
			padding: 12px 0 0;
			position: absolute;
			right: 15px;
			top: -116px;

			ul {
				margin: 0;
			}

			li {
				padding: 0 0 0 20px;
			}

			a {
				color: #fff;
				&:hover {
					text-decoration: underline;
				}
			}

		}

		@include breakpoint(desktop-m) {
			padding-top: 10px;
			font-size: 14px;
		}
	}
}

@include breakpoint(maxtablet) {
	body.navigation--open {
		overflow: hidden;
		position: fixed;
		width: 100%;

		.navigation {
			left: 0;
		}
	}

	.mn-indicator,
	.mn-button {
		display: none;
	}

	.navigation {
		background: rgba(0,0,0,0.6);
		height: 100%;
		left: -100%;
		overflow: hidden;
		position: fixed; 
		top: 0;
		width: 100%;
		transition: left $transitionTime;
		padding-right: 42px;
		z-index: 111;

		.container {
			display: flex;
			flex-direction: column;
			height: calc(100% - 42px);
			background: #fff;
			overflow-x: hidden;
			overflow-y: auto;
		}
		
		.mn-title {
			@include baseFont(700);
			background-color: $primaryColor;
			color: #fff;
			height: 42px;
			line-height: 42px;
			text-align: center;
		}

		a {
			display: block;
			padding: 6px 0;
		}

		ul {
			margin: 0;
		}
	}

	.mn-indicator {
		border-bottom: 1px solid #fff;
		margin: -1px -15px 0;
		position: relative;
		padding: 0 15px;
	}

	.mn-link {
		border-bottom: 1px solid $borderColor;
		margin: 0 -15px;
		position: relative;
		padding: 0 15px;

		&:last-of-type {
			border-bottom: none;
		}

		a {
			color: $fontColor;
		}

		span {
			position: absolute;
			top: 7px;
			font-size: 12px;
			right: 15px;
			color: $primaryColor;

			&.toggle--subnav--open {
				transform: rotate(-180deg);
			}
		}

		&.subnav--open {
			ul {
				max-height: 500px;
				padding-bottom: 6px;
			}
		}

		ul {
			max-height: 0;
			overflow: hidden;
			transition: max-height $transitionTime; 
		}
	}

	.top-links {
		background-color: $grayBg;
		margin: 26px -15px 0;
		padding: 0 15px;

		ul {
			margin: 8px 0;
		}

		li {
			position: relative;
			margin: 0 -15px;
			padding: 0 15px;
			width: calc(100% + 30px);

			&:last-child {
				border-bottom: 0;
			}
		}

		a {
			color: $fontColor;
		}
		
		li.active>a {
			color: $primaryColor;
		}
	}
}

@include breakpoint(maxmobile) {
	.navigation {
		.container {
			height: calc(100% - 45px);
		}
		.mn-title {
			height: 45px;
			line-height: 45px;
		}
	}
}


@include breakpoint(desktop) {
	.navigation {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	.mn-title {
		display: none;
	}

	.mainnav {
		position: relative;
		padding: 0;
		box-sizing: border-box;
		z-index: 101;
	}

	.mn-nav {
		overflow-x: hidden;
		overflow-y: visible;
		-webkit-overflow-scrolling: touch;
		white-space: nowrap;
		position: relative;
		font-size: 0; // !!important don`t remove!!

		.js & {
			-ms-overflow-style: -ms-autohiding-scrollbar;
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}

	.mn-content {
		float: left;
		transition: transform .2s ease-in-out;
		position: relative;
		margin: 0;
		padding: 0;
	}

	.mn-no-transition {
		transition: none;
	}

	.mn-link {
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		min-height: 44px;
		padding: 0 20px 0 0;

		a {
			@include baseFont(700);
			color: $fontColor;
			text-decoration: none;
			font-size: 14px;

			&:hover {
				color: $primaryColor;
			}
		}
		
		span,
		ul {
			display: none;
		}
	}

	.mn-button {
		/* Reset the button */
		appearance: none;
		background: $primaryColor;
		padding: 0;
		color: #fff;
		border: 0;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		margin-top: 14px;
		line-height: 22px;
		font-size: 12px;
		position: absolute;
		top: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity .3s;
		visibility: hidden;
		&:hover {
			cursor: pointer;
			background-color: $secondaryColor;
		}
	}

	.mn-button-left {
		padding-right: 1px;
		left: -2px;
		[data-overflowing="both"] ~ &,
		[data-overflowing="left"] ~ & {
			opacity: 1;
			visibility: visible;
		}
	}

	.mn-button-right {
		padding-left: 3px;
		right: -2px;
		[data-overflowing="both"]  ~ &,
		[data-overflowing="right"] ~ & {
			visibility: visible;
			opacity: 1;
		}
	}

	.mn-indicator {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 4px;
		width: 100px;
		background-color: transparent;
		transform-origin: 0 0;
		transition: transform .2s ease-in-out, background-color .2s ease-in-out;
	}
}
