.footer__top {
	background: $primaryColor;
	color: #fff;
	padding: 24px 0 0;
	margin-top: 24px;

	h4 {	
		color: #fff;
		font-size: 16px;
		margin-bottom: 4px;
	}

	a {
		color: #fff;
		&:hover {
			color: #fff;
			text-decoration: underline;
		}
	}

	strong {
		display: block;
	}

	span {
		font-size: 12px;
	}

	.col-sm-3 a:hover { text-decoration: none; }

	@include breakpoint(tablet) {
		margin-top: 36px;
		padding: 48px 0 0;

		h4 {
			margin-bottom: 23px;
		}
	}

	@include breakpoint(desktop-m) {
		margin-top: 50px;
	}
}

.footer__block {
	margin-bottom: 24px;

	@include breakpoint(tablet) {
		float: left;
		margin-bottom: 40px;
		width: 50%;
	}

	@include breakpoint(desktop) {

		/* one item */
		&:first-child:nth-last-child(1) {
			width: 100%;
		}

		/* two items */
		&:first-child:nth-last-child(2),
		&:first-child:nth-last-child(2) ~ & {
			width: 50%;
		}

		/* three items */
		&:first-child:nth-last-child(3),
		&:first-child:nth-last-child(3) ~ & {
			width: 33.3333%;
		}

		/* four items */
		&:first-child:nth-last-child(4),
		&:first-child:nth-last-child(4) ~ & {
			width: 25%;
		}
	}
} 

.footer__block--contact {
	width: 100%;

	h4 {
		margin-bottom: 8px;
	}

	div {
		position: relative;
		margin-bottom: 12px;
		padding-left: 35px;
		line-height: 1.2;

		&:last-child {
			margin-bottom: 0;
		}

		i {
			position: absolute;
			left: 0;
		}
	}

	.phone i {
		font-size: 28px;
		top: 4px;
	}
	.mail i {
		font-size: 20px;
		top: 5px;
	}
	.whatsapp i {
		font-size: 28px;
		top: 4px;
	}

	@include breakpoint(tablet) {
		h4 {
			margin-bottom: 24px;
		}

		div {
			line-height: 1.5;
		}

		.mail i {
			top: 8px;
		}
	}

	.socials {
		width: 100%;
		display: flex;

		a {
			display: block;
			margin: 15px 15px 0 0;
		}

		i {
			position: static;
			font-size: 25px;
		}
	}
}