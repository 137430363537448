/*============================
=            Base            =
============================*/

*, *:after, *:before {
	box-sizing: inherit;
}

html, body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	position: relative;
}

html {
	box-sizing: border-box;
	overflow-x: hidden;
}

body {
	@include baseFont();
	background: $backgroundColor;
	color: $fontColorGray;
	font-size: 14px;
	line-height: 1.5;
	min-width: 320px;
	margin: 0;
	-webkit-backface-visibility:hidden;
	text-align: left;
	transition: padding $transitionTime;
}

.clearfix {
	@include clearfix;
}

.container {
	@include clearfix();
	max-width: $siteWidth;
	width: 100%;
}

@include breakpoint(maxmobile) {
	.pr-l + .col-sm-3,
	.col-sm-6 + .col-sm-6 {
		margin-top: 24px;
	}
}

@include breakpoint(desktop-m) {
	.pr-l {
		padding-right: 70px;
	}
}

/* Color selected content */
::selection,
::-moz-selection {
	background: #abd0fc;
}

/*
 * Row with equal height columns
 * --------------------------------------------------
 */
 .row-eq-height {
 	@include breakpoint(tablet) {
 		display: flex;
 		flex-wrap: wrap;
 		width: calc(100% + 30px);
 	}
 }

 button:focus,
 *:focus {
 	outline: none;
 }

 img {
 	max-width: 100%;
 }