/*====================================
=            Contact form            =
====================================*/
textarea, input, button { 
	outline: none; 
}

/* Change the white to any color ;) */
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 34px $inputBg inset;
}

input[type="password"],
input[type="reset"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="text"],
textarea {   
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}


.form-group,
.input-group {
	.form-control {
		background-color: $inputBg;
		border-radius: $borderRadius;
		border: 1px solid $borderColor;
		color: $fontColor;
		box-shadow: none;
	}
	textarea.form-control {
		height: 175px;		
	}

	select {
		-webkit-appearance: none;
		-moz-appearance:none;
		background-color: white;
		background-image: url('data:image/svg+xml;utf8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"/></svg>');
		background-position: calc(100% - 12px) center;
		background-size: 12px;
		background-repeat: no-repeat;
		padding-right: 1.5em;
	}
}

.form-group .form-control + a {
	display: block;
	margin-top: 4px;
}

.form-control {
	background-color: $inputBg;
	border-radius: $borderRadius;
	border: 1px solid $borderColor;
	color: $fontColor;
	box-shadow: none;
}

input + .text-danger {
	display: block;
	margin-top: 4px;
}

input[type=checkbox] {
	background-color: $inputBg;
	border-radius: $borderRadius;
	border: 1px solid $borderColor;
	box-shadow: none;
	width: 15px;
	height: 15px;
}

.control-label {
	color: $fontColor;
}

.form-group-quantity {
	span {
		color: $fontColor;
		display: block;
		margin-top: 4px;
	}
		.form-control {
			width: 25%;
			display: inline-block;

			+ span {
				display: inline-block;
				margin: 0 0 0 12px;
			}
		}
}

.form-horizontal {
	@include clearfix;
}

.input-group.date {
	.fa-calendar {
		margin: 0;
	}
}

.radios {
	margin-bottom: 24px;
	margin-top: -12px;
}

.checkbox-agree,
.checkbox-inline {
	margin-right: 14px;
	margin-top: 6px;
}

@include breakpoint(maxmobile) {
	.form-horizontal .checkbox, 
	.form-horizontal .checkbox-inline, 
	.form-horizontal .radio, 
	.form-horizontal .radio-inline {
		margin-bottom: 12px;
	}
}

fieldset {
	border-color: $borderColor;
	border-radius: $borderRadius;
	margin: -6px 0 24px;
	padding: 15px 15px 15px;

	legend {
		@include baseFont(600);
		font-size: 14px;
		color: $fontColor;
		margin-bottom: 4px;
		padding: 4px 12px;
		display: inline-block;
		width: auto;
		margin-left: -12px;
	}

	@include breakpoint(desktop) {
		padding: 15px 30px;
	}
}