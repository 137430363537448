.price {
	strong {
		font-size: 16px;
		margin-right: 4px;
	}

	&-tax {
		color: $fontColorGray;
		font-size: 12px;
	}
	&-old {
		text-decoration: line-through;
	}
	&-new {
		color: $sale;
	}
}

.product-thumb {
	border-radius: $borderRadius;
	border: 1px solid $borderColor;
	height: 100%;
	margin-bottom: 24px;
	padding-bottom: 42px;
	position: relative;

	.product-sale {
		@include baseFont(600);
		background: $sale;
		bottom: 0;
		color: #fff;
		left: 0;
		font-size: 12px;
		padding: 4px 16px;
		position: absolute;
	}

	.image {
		position: relative;

		a {
			display: block;
		}

		img {
			margin: 6px auto 0;
		}
	}

	.caption {
		padding: 12px 16px 64px 16px;

		h4 {
			font-size: 16px;
			margin-bottom: 6px;
		}

		a {
			color: $fontColor;
			&:hover {
				color: $primaryColor;
			}
		}

		dl {
			@include clearfix();
			margin: 0;

			dt, dd {
				@include baseFont(400);
				color: $fontColorGray;
				float: left;
				margin: 0;
				line-height: 150%;
				width: 100px;
			}

			dt {
				clear: left;
			}

			dd {
				width: calc(100% - 100px);
				padding-left: 6px;
			}
		}
	}

	.price {
		bottom: 65px;
		color: $fontColor;
		left: 0;
		position: absolute;
		padding: 0 15px 0 15px;
		width: 100%;

		p {
			color: $fontColorGray;
			font-size: 12px;
		}
	}

	.button-group {
		bottom: -1px;
		left: -1px;
		position: absolute;
		width: calc(100% + 2px);
		display: table;

		.input-group {
			padding: 0;
			
			.form-control {
				@include baseFont(600);
				background: $inputBg;
				font-size: 14px;
				height: 42px;
				padding: 8px 16px;
				border-radius: 0 0 0 $borderRadius;

			}
		}

		.btn, .input-group {
			display: table-cell;
			vertical-align: bottom;
		}

		.btn {
			border-radius: 0 0 $borderRadius 0;
			width: 100%;
		}
	}
}

.product__wrapper .row {
	@include breakpoint(maxmobile) {
		overflow: hidden;
		clear: both;
	}
}

.product-layout {
	@include breakpoint(maxmobile) {
		margin: 0 -15px;
		width: calc(100% + 30px);

		.product-thumb {
			@include clearfix;
			border-left: none;
			border-right: 0;
			border-radius: 0;
			margin: -25px 0 0 0;
			padding: 0 20px 20px 30%;
		}

		.image {
			position: absolute;
			top: 20px;
			left: 20px;
			width: 25%;
		}

		.caption {
			padding-bottom: 0;
		}

		.price {
			bottom: auto;
			margin-top: 16px;
			position: relative;
		}

		.button-group {
			bottom: auto;
			margin-top: 10px;
			position: relative;
			width: auto;
			
			input,
			.btn {
				border-radius: 0;
			}

			.btn {
				min-width: 65px;
				span {
					display: none;
				}
			}
		}

		&:first-child .product-thumb  {
			margin-top: 0;
		}
	}

	@include breakpoint(tablet) {
		margin-bottom: 24px;
	}
}
