/*===================================
=            Breakpoints            =
===================================*/

@mixin breakpoint($point) {
	@if $point == mobile {
		@media (min-width: 480px) { @content; }
	}

	@else if $point == tablet {
		@media (min-width: 767px) { @content; }
	}

	@else if $point == maxmobile {
		@media (max-width: 767px) { @content; }
	}

	@else if $point == desktop {
		@media (min-width: 1024px) { @content; }
	}

	@else if $point == maxtablet {
		@media (max-width: 1023px) { @content; }
	}

	@else if $point == maxdesktop {
		@media (max-width: 1025px) { @content; }
	}

	@else if $point == desktop-m {
		@media (min-width: 1150px) { @content; }
	}

	@else if $point == desktop-l {
		@media (min-width: 1300px) { @content; }
	}
	@else if $point == desktop-xl {
		@media (min-width: 1600px) { @content; }
	}

	@else if $point == chrome {
		@media screen and (-webkit-min-device-pixel-ratio:0) { @content; }
	}

	@else {
		@warn "Breakpoint mixin supports: mobile, tablet, desktop, desktop-m, desktop-l";
	}
}

