.blogpost {
	position: relative;
	margin-top: -25px;

	.blogpost__header {
		margin-bottom: 25px;
	}

	.blogpost__content {
		background: #fff;
	}

	.blogpost__meta {
		margin-bottom: 8px;

		span { display: block; }
		.fa { 
			color: $fontColorGray;
			margin-right: 4px; 
		}

		a {
			color: $fontColorGray;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.title {
		@include fluidFont(24px, 32px);
		color: $primaryColor;

		+ p {
			@include fluidFont(16px, 18px);
		}
	}

	h1:not(.title), h2 , h3, h4, h5, h6 {
		margin-bottom: 4px;
	}

	h2 , h3, h4, h5, h6 {
		color: $fontColorGray;
	}

	h1:not(.title) {
		@include fluidFont(20px, 24px);
	}

	h2 {
		@include fluidFont(18px, 20px);
	}

	h3 {
		font-size: 16px;
	}

	@include breakpoint(tablet) {
		.blogpost__header {
			&--empty {
				height: 145px;
				background: $primaryColor;
			}
		}
		.blogpost__content {
			background: #fff;
			margin: -80px 20px 0;
			padding: 32px 46px;
			position: relative;
			width: calc(100% - 40px);
		}

		.blogpost__meta span {
			display: inline-block;
			margin-right: 8px;
		}
	}

	@include breakpoint(desktop) {
		.blogpost__content {
			width: 900px;
			margin: -85px auto 0;
			padding: 40px 100px 0;
		}

		.blogpost__header--empty + .blogpost__content {
			margin-top: -105px;
			padding-top: 50px;
		}
	}
}