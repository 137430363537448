@charset "UTF-8";

@function unicode($str) {
	@return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"");
}

// For adding font icons to elements using CSS pseudo-elements
@mixin icon($position: before, $icon: false, $styles: true) {
	@if $position == both {
		$position: 'before, &:after';
	}
	// Either a :before or :after pseudo-element, or both, defaulting to :before
	&:#{$position} {
		@if $icon {
			// A particular icon has been specified
			content: unicode(#{$icon});
		}
		@if $styles {
			// Supportive icon styles required
			speak: none;
			font-style: normal;
			font-weight: 900;
			font-family: FontAwesome;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			// Include any extra rules supplied for the pseudo-element
			@content;
		}
	}
}

// Set the required styles on all icons
[class^="icon-"],
[class*=" icon-"] {
	@include icon;
}

// Setup a class name for each icon
@each $name, $char in $icons {
	.fa-#{$name}:before {
		content: $char;
	}
}