.mfp-bg {
	opacity: 0.55;
}

.mfp-zoom-out-cur {
	cursor: default;
}

.mfp-close-btn-in .mfp-close,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close,
.mfp-close {
	background-color: $primaryColor;
	color: #fff;
	cursor: pointer;
	opacity: 1;
	border-radius: 0;

	&:hover {
		background-color: $secondaryColor;
	}

	@include breakpoint(desktop) {
		height: 60px;
		width: 60px;
		text-align: center;
		padding: 0;
		font-size: 40px;
		line-height: 40px;
	}
}

.mfp-iframe-holder .mfp-close, 
.mfp-image-holder .mfp-close {
	right: 0;
	top: 40px;
}

button.mfp-arrow {
	opacity: 1;
	&:before,
	&:after {
		display: none;
	}

	.btn {
		border-radius: 0;
		background: #69686b;
		height: 50px;
		padding: 16px 0 0;
		text-align: center;
		width: 50px;
		&:before {
			color: #fff !important;
			font-size: 18px;
		}

		&:hover {
			background: $fontColor;
		}
	}

	&:active {
		margin-top: -55px;
	}
}

.white-popup {
	@include clearfix();
	background: #fff;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
	margin: 40px auto;
	max-width: 840px;
	padding: 35px 20px;
	position: relative;
	text-align: left;

	@include breakpoint(desktop) {
		padding: 40px 35px;
		
		p {
			margin-bottom: 40px;
		}
	}

	p, li {
		@include fluidFont(14px, 16px);
		line-height: 190%;
	}
}

/**
 * Simple fade transition,
 */
 .mfp-fade.mfp-bg {
 	opacity: 0;
 	transition: all 0.15s ease-out;
 }
 .mfp-fade.mfp-bg.mfp-ready {
 	opacity: 0.8;
 }
 .mfp-fade.mfp-bg.mfp-removing {
 	opacity: 0;
 }

 .mfp-fade.mfp-wrap .mfp-content {
 	opacity: 0;
 	transition: all 0.15s ease-out;
 }
 .mfp-fade.mfp-wrap.mfp-ready .mfp-content {
 	opacity: 1;
 }
 .mfp-fade.mfp-wrap.mfp-removing .mfp-content {
 	opacity: 0;
 }