.additional-info {
	border: 1px solid $borderColor;
	border-radius: $borderRadius;
	padding: 15px;
	margin-bottom: 20px;

	p {
		color: $fontColorGray;
		margin-bottom: 8px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	strong {
		color: $fontColor;
	}

	a {
		color: $primaryColor;
		&:hover {
			text-decoration: underline;
		}
	}

	@include breakpoint(tablet) {
		padding: 20px;
	}
}