.slideshow {
	border-radius: $borderRadius;
	margin: 24px 0;
	position: relative;

	.slick-arrow {
		color: rgba(255,255,255,0.6);
		position: absolute;
		top: 50%;
		transition: color $transitionTime;
		transform: translateY(-50%);
		z-index: 1;

		&:hover { color: #fff; }
		i:before { 
			font-size: 20px; 
			text-shadow: 0px 0px 10px rgba(0,0,0,0.24);
		}

		&.slick-left { left: 10px; }
		&.slick-right { right: 10px; }
	}

	.slide {
		border-radius: $borderRadius;
		background-size: cover;
		background-position: center;
		color: #fff;
		height: 325px;
		text-align: center;
		overflow: hidden;
		display: flex;
		padding: 0 30px;
		justify-content: center;
		align-items: center;
	}

	h3 {
		@include fluidFont(22px, 32px);
		color: #fff;
		margin-bottom: 18px;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.24);
	}

	p {
		@include fluidFont(13px, 15px);
		text-shadow: 0px 0px 3px rgba(0,0,0,0.64);
		margin-bottom: 42px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include breakpoint(tablet) {
		.slick-arrow {
			i:before { font-size: 36px; }

			&.slick-left { left: 20px; }
			&.slick-right { right: 20px; }
		}

		.slide {
			height: 275px;
			padding: 0 60px;
		}
	}
}