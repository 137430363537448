$brandName: 'main' !default;

/* Url`s */
$imgUrl:    'catalog/view/theme/be/assets/images/';

/* Numbers */
$siteWidth: 			1140px !default;
$borderRadius: 			4px !default;

/* Colors */
$succes:				#18b100 !default;
$notice: 				#ffc372 !default;
$error: 				#dc1818 !default;
$attention: 			#f39300 !default;
$sale:					#e63935 !default;

$primaryColor:		    #5484C4 !default;
$primaryColorHover:	    #3e6395 !default;
$secondaryColor:		#4CB944 !default;
$secondaryColorHover: 	#309d28 !default; 
$fontColor:             #03121A !default;
$fontColorGray:			#707070 !default;
$backgroundColor: 		#ffffff !default;
$grayBg: 				#f7f7f6 !default;

$borderColor: 			#ededed !default;
$inputBg:				#fafafa !default;
$inputBorderColor: 		#ededed !default;

$urlColor: 				 $primaryColor !default;
$urlColorHover: 		 $fontColor !default;

/* Timing */
$transitionTime:		225ms !default;
