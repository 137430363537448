.product-product {
	.price {
		margin-bottom: 24px;
	}

	.form-group,
	.input-group {
		.form-control {
			height: 50px; 
		}
	}
}

.toggle-discounts {
	margin-left: 6px;

	&:hover {
		color: $urlColor;
		text-decoration: underline;
	}
}

.discounts {
	overflow: hidden;
	max-height: 0;
	transition: max-height $transitionTime;

	&.discounts-open {
		max-height: 500px;
	}
}

.amount {
	font-size: 12px;
}
.tax {
	display: block;
	strong {
		font-size: 12px;
	}
}

.stock {
	float: left;
	margin:8px 0 30px;
	padding-left: 30px;
	position: relative;
	width: 100%;

	&--green { color: $succes; }
	&--orange { color: $attention; }
	&--red { color: $error; }

	i {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		font-size: 24px;
	}
}

.product-nav-tabs {
	margin-top: 35px;

	li a {
		padding: 10px 0;
	}

	+ .tab-content {

		ul {
			margin-bottom: 18px;
			padding-left: 18px;
		}

		h4 {
			margin-bottom: 20px;
		}
	}
	
	@include breakpoint(tablet) {
		li {
			&:nth-child(1) a {
				margin-left: 0;
			}
			a {
				margin: 0 15px;
			}
		}
	}
}

.product-gallery .sale {
	@include baseFont(600);
	background: $sale;
	border-radius: 0 $borderRadius 0 0;
	top: 0;
	color: #fff;
	right: 15px;
	font-size: 12px;
	padding: 4px 16px;
	position: absolute;
}

.product-description {
	.EZ-base { display: none; }
}

.specifications__table {
	margin-bottom: 22px;

	dl {
		@include clearfix();
		margin: 0;
		padding: 8px 15px;

		&:nth-child(odd) { background: $grayBg; }
	}

	dd, dt {
		@include baseFont(400);
		float: left;
	}

	dt { width: 40%; }
	dd { width: 60%; }
}

.productslider-for {
	display: none;
	margin-bottom: 24px;
	border: 1px solid $borderColor;
	border-radius: $borderRadius;

	.slick-track{
		display: flex;

		.slick-slide{
			display: flex;
			height: auto;
			align-items: center; //optional
			justify-content: center; //optional
		}
	}

	.video-wrapper {
		width: 100%;
	}
	.video-container {
		position:relative;
		padding-bottom:56.25%;
		height:0;
		overflow:hidden;

		iframe {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	.slick-slide > div {
		display: inline-block;
	}

	img {
		display: block;
		margin: 0 auto;
		padding: 0 50px;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		right: 0;
		cursor: pointer;
		display: block;
		z-index: 8;
		margin-top: -14px;
		background: #69686b;
		width: 40px;
		padding-left: 2px;
		height: 40px;
		text-align: center;

		&:hover {
			background: $fontColor;
		}

		.fa {
			color: #fff;
			font-size: 16px;
			line-height: 40px;
		}

		&.slick-left {
			left: 0;
			padding: 0 2px 0 0;
			right: auto;
		}
	}

	@include breakpoint(tablet) {
		margin-bottom: 10px;
	}
}

.productslider-nav {
	display: none;
	margin-left: -5px;
	margin-right: -5px;

	.slick-track {
		display: flex;

		.slick-slide {
			display: flex;
			height: auto;
			align-items: center; //optional
			justify-content: center; //optional
		}
	}

	@include breakpoint(maxmobile) {
		display: none !important;
	}

	&.productslide .slick-track {
		width: auto !important;
	}

	.product__video {
		position: relative;
		@include icon(before, f144) {
			border-radius: 50%;
			line-height: 38px;
			background: #fff;
			color: $fontColor;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 45px;
		}
	}
	
	.product__video div,
	.product__image div {
		border: 1px solid $borderColor;
		border-radius: $borderRadius;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 5px;
		width: calc(100% - 10px);
	}

	.product__image.slick-current img {
		border-color: #1b2227;		
	}
}