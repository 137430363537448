/*===================================
=            Breadcrumbs            =
===================================*/

.breadcrumb {
	@include clearfix;
	background: #fff;
	border-bottom: 1px solid $borderColor;
	border-top: 1px solid $borderColor;
	border-radius: 0;
	color: $fontColor;
	font-size: 12px;
	margin-bottom: 24px;
	padding: 14px 0;
	position: relative;

	@include breakpoint(tablet) {
		display: block;
		border-top: 0;
	}

	ul {
		@include clearfix;
		display: inline-block;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		color: $fontColor;
		display: inline-block;
		font-size: 12px;
		vertical-align: middle;

		&:before { display: none; }

		@include icon(after, 'f054') {
			color: $primaryColor;
			font-size: 8px;
			vertical-align: middle;
			margin: 0 8px 0 10px;
		}

		&:last-child:after {
			display: none;
		}
	}

	a {
		color: $fontColor;
		display: inline-block;
		font-size: 12px;
		text-decoration: none;
		vertical-align: middle;

		&:hover {
			color: $primaryColor;
			text-decoration: none;
		}
	}
}

