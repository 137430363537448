.list-group-clean {

	.list-group-item.active {
		background: #fff;
		color: $fontColor;
		
		&:focus, 
		&:hover {
			background: #fff;
			color: $primaryColor;
		}
	}

	.list-group-parent {
		@include baseFont(700);

		.fa {
			color: $primaryColor;
			font-size: 12px;
			margin-right: 5px;
		}

		&:hover {
			color: $primaryColor;
		}
	}

	a.list-group-item:focus, 
	a.list-group-item:hover, 
	button.list-group-item:focus, 
	button.list-group-item:hover {
		color: $primaryColor;
		background: #fff;
	}

	.list-group-item {
		padding: 6px 0;
		@include baseFont(600);
		border: none;
		color: $fontColorGray;
	}
}