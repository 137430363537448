/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover,
  .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover,
  .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before,
  .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/*

Author:     Vivars
Project: 	Bevestigingsexpert.nl

*/
/*

Author:     Vivars
Project: 	Main

*/
/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */ }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Settings */
/* Url`s */
/* Numbers */
/* Colors */
/* Timing */
/* Tools */
/*animations*/
/******************
* Bounce in right *
*******************/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.slow {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.slower {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.slowest {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

/* Added by Andy Meetan */
.delay-250 {
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s; }

.delay-500 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s; }

.delay-750 {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s; }

.delay-1000 {
  -webkit-animation-delay: 1.0s;
  -moz-animation-delay: 1.0s;
  -o-animation-delay: 1.0s;
  animation-delay: 1.0s; }

.delay-1250 {
  -webkit-animation-delay: 1.25s;
  -moz-animation-delay: 1.25s;
  -o-animation-delay: 1.25s;
  animation-delay: 1.25s; }

.delay-1500 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s; }

.delay-1750 {
  -webkit-animation-delay: 1.75s;
  -moz-animation-delay: 1.75s;
  -o-animation-delay: 1.75s;
  animation-delay: 1.75s; }

.delay-2000 {
  -webkit-animation-delay: 2.0s;
  -moz-animation-delay: 2.0s;
  -o-animation-delay: 2.0s;
  animation-delay: 2.0s; }

.delay-2500 {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.delay-2000 {
  -webkit-animation-delay: 2.0s;
  -moz-animation-delay: 2.0s;
  -o-animation-delay: 2.0s;
  animation-delay: 2.0s; }

.delay-2500 {
  -webkit-animation-delay: 2.5s;
  -moz-animation-delay: 2.5s;
  -o-animation-delay: 2.5s;
  animation-delay: 2.5s; }

.delay-3000 {
  -webkit-animation-delay: 3.0s;
  -moz-animation-delay: 3.0s;
  -o-animation-delay: 3.0s;
  animation-delay: 3.0s; }

.delay-3500 {
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  -o-animation-delay: 3.5s;
  animation-delay: 3.5s; }

.bounceInRight, .bounceInLeft, .bounceInUp, .bounceInDown {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.fadeInRight, .fadeInLeft, .fadeInUp, .fadeInDown {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.flipInX, .flipInY, .rotateIn, .rotateInUpLeft, .rotateInUpRight, .rotateInDownLeft, .rotateDownUpRight, .rollIn {
  opacity: 0; }

.lightSpeedInRight, .lightSpeedInLeft {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

/***********
* bounceIn *
************/
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3); }
  50% {
    opacity: 1;
    transform: scale(1.05); }
  70% {
    transform: scale(0.9); }
  100% {
    transform: scale(1); } }

.bounceIn.go {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn; }

/****************
* bounceInRight *
****************/
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px); }
  60% {
    -webkit-transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(400px); }
  60% {
    transform: translateX(-30px); }
  80% {
    transform: translateX(10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.bounceInRight.go {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight; }

/******************
* Bounce in left *
*******************/
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px); }
  60% {
    -webkit-transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-400px); }
  60% {
    transform: translateX(30px); }
  80% {
    transform: translateX(-10px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.bounceInLeft.go {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft; }

/******************
* Bounce in up *
*******************/
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px); }
  60% {
    -webkit-transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(400px); }
  60% {
    transform: translateY(-30px); }
  80% {
    transform: translateY(10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.bounceInUp.go {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp; }

/******************
* Bounce in down *
*******************/
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px); }
  60% {
    -webkit-transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-400px); }
  60% {
    transform: translateY(30px); }
  80% {
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.bounceInDown.go {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown; }

/**********
* Fade In *
**********/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: block; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: block; } }

.fadeIn {
  opacity: 0; }

.fadeIn.go {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

/**********
* Grow in *
***********/
@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0; }
  50% {
    -webkit-transform: scale(1.2); }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes growIn {
  0% {
    transform: scale(0.2);
    opacity: 0; }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1);
    opacity: 1; } }

.growIn {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  opacity: 0; }

.growIn.go {
  -webkit-animation-name: growIn;
  animation-name: growIn; }

/********
* Shake *
********/
@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateX(10px); } }

.shake.go {
  -webkit-animation-name: shake;
  animation-name: shake; }

/********
* ShakeUp *
********/
@-webkit-keyframes shakeUp {
  0%, 100% {
    -webkit-transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateY(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateY(10px); } }

@keyframes shakeUp {
  0%, 100% {
    transform: translateY(0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translateY(-10px); }
  20%, 40%, 60%, 80% {
    transform: translateY(10px); } }

.shakeUp.go {
  -webkit-animation-name: shakeUp;
  animation-name: shakeUp; }

/*************
* FadeInLeft *
*************/
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-400px);
  transform: translateX(-400px); }

.fadeInLeft.go {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

/*************
* FadeInRight *
*************/
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(400px);
  transform: translateX(400px); }

.fadeInRight.go {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

/*************
* FadeInUp *
*************/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(400px);
  transform: translateY(400px); }

.fadeInUp.go {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

/*************
* FadeInDown *
*************/
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-400px); }
  50% {
    opacity: 0.3; }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-400px);
  transform: translateY(-400px); }

.fadeInDown.go {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

/*****************
* rotateIn *
*****************/
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0; }
  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1; } }

.rotateIn.go {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn; }

/*****************
* rotateInUpLeft *
*****************/
@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpLeft.go {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft; }

/*******************
* rotateInDownLeft *
*******************/
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownLeft.go {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft; }

/******************
* rotateInUpRight *
*******************/
@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInUpRight.go {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight; }

/********************
* rotateInDownRight *
********************/
@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; } }

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0; }
  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; } }

.rotateInDownRight.go {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight; }

/*********
* rollIn *
**********/
@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg); } }

.rollIn.go {
  -webkit-animation-name: rollIn;
  animation-name: rollIn; }

/*********
* wiggle *
**********/
@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg); }
  10% {
    -webkit-transform: skewX(-8deg); }
  20% {
    -webkit-transform: skewX(7deg); }
  30% {
    -webkit-transform: skewX(-6deg); }
  40% {
    -webkit-transform: skewX(5deg); }
  50% {
    -webkit-transform: skewX(-4deg); }
  60% {
    -webkit-transform: skewX(3deg); }
  70% {
    -webkit-transform: skewX(-2deg); }
  80% {
    -webkit-transform: skewX(1deg); }
  90% {
    -webkit-transform: skewX(0deg); }
  100% {
    -webkit-transform: skewX(0deg); } }

@keyframes wiggle {
  0% {
    transform: skewX(9deg); }
  10% {
    transform: skewX(-8deg); }
  20% {
    transform: skewX(7deg); }
  30% {
    transform: skewX(-6deg); }
  40% {
    transform: skewX(5deg); }
  50% {
    transform: skewX(-4deg); }
  60% {
    transform: skewX(3deg); }
  70% {
    transform: skewX(-2deg); }
  80% {
    transform: skewX(1deg); }
  90% {
    transform: skewX(0deg); }
  100% {
    transform: skewX(0deg); } }

.wiggle.go {
  -webkit-animation-name: wiggle;
  animation-name: wiggle;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/********
* swing *
*********/
@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

@keyframes swing {
  20% {
    transform: rotate(15deg); }
  40% {
    transform: rotate(-10deg); }
  60% {
    transform: rotate(5deg); }
  80% {
    transform: rotate(-5deg); }
  100% {
    transform: rotate(0deg); } }

.swing.go {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing; }

/*******
* tada *
********/
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    transform: scale(1); }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg); }
  100% {
    transform: scale(1) rotate(0); } }

.tada.go {
  -webkit-animation-name: tada;
  animation-name: tada; }

/*********
* wobble *
**********/
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%); } }

@keyframes wobble {
  0% {
    transform: translateX(0%); }
  15% {
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    transform: translateX(20%) rotate(3deg); }
  45% {
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    transform: translateX(10%) rotate(2deg); }
  75% {
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    transform: translateX(0%); } }

.wobble.go {
  -webkit-animation-name: wobble;
  animation-name: wobble; }

/********
* pulse *
*********/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1); } }

@keyframes pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

.pulse.go {
  -webkit-animation-name: pulse;
  animation-name: pulse; }

/***************
* lightSpeedInRight *
****************/
@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedInRight {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1; }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1; }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedInRight.go {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

/***************
* lightSpeedInLeft *
****************/
@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0; }
  60% {
    -webkit-transform: translateX(20%) skewX(-30deg);
    opacity: 1; }
  80% {
    -webkit-transform: translateX(0%) skewX(15deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

@keyframes lightSpeedInLeft {
  0% {
    transform: translateX(-100%) skewX(30deg);
    opacity: 0; }
  60% {
    transform: translateX(20%) skewX(-30deg);
    opacity: 1; }
  80% {
    transform: translateX(0%) skewX(15deg);
    opacity: 1; }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; } }

.lightSpeedInLeft.go {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

/*******
* Flip *
*******/
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in; }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in; } }

.flip.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip; }

/**********
* flipInX *
**********/
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    transform: perspective(400px) rotateX(10deg); }
  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; } }

.flipInX.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX; }

/**********
* flipInY *
**********/
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg); }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; }
  40% {
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    transform: perspective(400px) rotateY(10deg); }
  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; } }

.flipInY.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY; }

/*****************
* Out animations *
*****************/
/************
* bounceOut *
*************/
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    transform: scale(1); }
  25% {
    transform: scale(0.95); }
  50% {
    opacity: 1;
    transform: scale(1.1); }
  100% {
    opacity: 0;
    transform: scale(0.3); } }

.bounceOut.goAway {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut; }

/************
* bounceOutUp *
*************/
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(20px); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.bounceOutUp.goAway {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp; }

/************
* bounceOutDown *
*************/
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    transform: translateY(2000px); } }

.bounceOutDown.goAway {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown; }

/************
* bounceOutLeft *
*************/
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0); }
  20% {
    opacity: 1;
    transform: translateX(20px); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.bounceOutLeft.goAway {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft; }

/************
* bounceOutRight *
*************/
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    transform: translateX(0); }
  20% {
    opacity: 1;
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.bounceOutRight.goAway {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight; }

/************
* fadeOut *
*************/
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut.goAway {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

/************
* fadeOutUp *
*************/
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(-2000px); } }

.fadeOutUp.goAway {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp; }

/************
* fadeOutDown *
*************/
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0); }
  100% {
    opacity: 0;
    transform: translateY(2000px); } }

.fadeOutDown.goAway {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown; }

/************
* fadeOutLeft *
*************/
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(-2000px); } }

.fadeOutLeft.goAway {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft; }

/************
* fadeOutRight *
*************/
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0); }
  100% {
    opacity: 0;
    transform: translateX(2000px); } }

.fadeOutRight.goAway {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight; }

/************
* flipOutX *
*************/
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0; } }

.flipOutX.goAway {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important; }

/************
* flipOutY *
*************/
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1; }
  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY; }

/************
* lightSpeedOutRight *
*************/
@-webkit-keyframes lightSpeedOutRight {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

@keyframes lightSpeedOutRight {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0; } }

.lightSpeedOutRight.goAway {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/************
* lightSpeedOutLeft *
*************/
@-webkit-keyframes lightSpeedOutLeft {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0; } }

@keyframes lightSpeedOutLeft {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1; }
  100% {
    transform: translateX(-100%) skewX(30deg);
    opacity: 0; } }

.lightSpeedOutLeft.goAway {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in; }

/************
* rotateOut *
*************/
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0; } }

@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0; } }

.rotateOut.goAway {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut; }

/************
* rotateOutUpLeft *
*************/
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0; } }

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0; } }

.rotateOutUpLeft.goAway {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft; }

/************
* rotateOutDownLeft *
*************/
@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutDownLeft.goAway {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft; }

/************
* rotateOutUpRight *
*************/
@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1; }
  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0; } }

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1; }
  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0; } }

.rotateOutUpRight.goAway {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight; }

/************
* rollOut *
*************/
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg); } }

.rollOut.goAway {
  -webkit-animation-name: rollOut;
  animation-name: rollOut; }

/*****************
* Short Animations
*******************/
/*********************
* fadeInUpShort
*********************/
@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    transform: translateY(20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInUpShort {
  opacity: 0;
  -webkit-transform: translateY(20px);
  transform: translateY(20px); }

.fadeInUpShort.go {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

/*********************
* fadeInDownShort
*********************/
@-webkit-keyframes fadeInDownShort {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0); } }

@keyframes fadeInDownShort {
  0% {
    opacity: 0;
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

.fadeInDownShort {
  opacity: 0;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px); }

.fadeInDownShort.go {
  -webkit-animation-name: fadeInDownShort;
  animation-name: fadeInDownShort; }

/*********************
* fadeInRightShort 
*********************/
@-webkit-keyframes fadeInRightShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInRightShort {
  0% {
    opacity: 0;
    transform: translateX(20px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInRightShort {
  opacity: 0;
  -webkit-transform: translateX(20px);
  transform: translateX(20px); }

.fadeInRightShort.go {
  -webkit-animation-name: fadeInRightShort;
  animation-name: fadeInRightShort; }

/*********************
* fadeInLeftShort 
*********************/
@-webkit-keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0); } }

@keyframes fadeInLeftShort {
  0% {
    opacity: 0;
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

.fadeInLeftShort {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px); }

.fadeInLeftShort.go {
  -webkit-animation-name: fadeInLeftShort;
  animation-name: fadeInLeftShort; }

/*===================================
=            Breakpoints            =
===================================*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  speak: none;
  font-style: normal;
  font-weight: 900;
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto; }

/* Generic */
.well {
  background-color: #fff;
  border-color: #ededed;
  border-radius: 4px;
  box-shadow: none; }

/*============================
=            Base            =
============================*/
*, *:after, *:before {
  box-sizing: inherit; }

html, body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  position: relative; }

html {
  box-sizing: border-box;
  overflow-x: hidden; }

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #ffffff;
  color: #707070;
  font-size: 14px;
  line-height: 1.5;
  min-width: 320px;
  margin: 0;
  -webkit-backface-visibility: hidden;
  text-align: left;
  transition: padding 225ms; }

.clearfix::after {
  clear: both;
  content: "";
  display: block; }

.container {
  max-width: 1140px;
  width: 100%; }
  .container::after {
    clear: both;
    content: "";
    display: block; }

@media (max-width: 767px) {
  .pr-l + .col-sm-3,
  .col-sm-6 + .col-sm-6 {
    margin-top: 24px; } }

@media (min-width: 1150px) {
  .pr-l {
    padding-right: 70px; } }

/* Color selected content */
::selection,
::-moz-selection {
  background: #abd0fc; }

/*
 * Row with equal height columns
 * --------------------------------------------------
 */
@media (min-width: 767px) {
  .row-eq-height {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 30px); } }

button:focus,
*:focus {
  outline: none; }

img {
  max-width: 100%; }

/*==================================
=            Typography            =
==================================*/
*:focus {
  outline: none; }

/*----------  Headings  ----------*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #03121A;
  line-height: 1.15;
  margin-top: 0; }
  h1 span, .h1 span, h2 span, .h2 span, h3 span, .h3 span, h4 span, .h4 span, h5 span, .h5 span, h6 span, .h6 span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal; }

h1, .h1 {
  font-size: calc(18px + 6 * ( (100vw - 320px) / 680)); }
  @media screen and (max-width: 320px) {
    h1, .h1 {
      font-size: 18px; } }
  @media screen and (min-width: 1000px) {
    h1, .h1 {
      font-size: 24px; } }
  h1.title, .h1.title {
    margin: 0 0 28px;
    text-align: left; }

h2, .h2 {
  font-size: calc(18px + 6 * ( (100vw - 320px) / 680)); }
  @media screen and (max-width: 320px) {
    h2, .h2 {
      font-size: 18px; } }
  @media screen and (min-width: 1000px) {
    h2, .h2 {
      font-size: 24px; } }

h3, .h3 {
  font-size: 22px; }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 16px; }

h6, .h6 {
  font-size: 16px; }

.title {
  margin: 12px 0 24px;
  float: left;
  line-height: 1.3;
  text-align: center;
  width: 100%; }
  @media (min-width: 767px) {
    .title {
      line-height: 1.15; } }

/*----------  Paragraphs  ----------*/
strong {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal; }

p {
  margin-top: 0;
  font-size: 14px;
  line-height: 1.5; }
  p.aligncenter {
    text-align: center; }
  p.alignleft {
    text-align: left; }
  p.alignright {
    text-align: right; }
  p.success {
    color: #18b100; }
  p.error {
    color: #dc1818; }
  p.notice {
    color: #ffc372; }

p {
  margin: 0 0 18px; }
  p:last-child {
    margin-bottom: 0; }

.list-styled {
  padding: 0;
  list-style: none; }
  .list-styled li {
    padding-left: 12px;
    position: relative;
    padding: 2px 0 2px 12px; }
    .list-styled li:before {
      content: "\f054";
      speak: none;
      font-style: normal;
      font-weight: 900;
      font-family: FontAwesome;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      font-size: 8px;
      left: 0;
      position: absolute;
      top: 8px; }
  @media (min-width: 767px) {
    .list-styled li {
      padding: 4px 0 4px 12px; }
      .list-styled li:before {
        top: 9px; } }

.list-dot {
  list-style: none;
  margin-bottom: 24px;
  padding: 0; }
  .list-dot::after {
    clear: both;
    content: "";
    display: block; }
  .list-dot li {
    display: block;
    float: left;
    margin: 4px 0;
    position: relative;
    padding-left: 24px;
    width: 100%; }
    .list-dot li:before {
      content: '';
      background: #03121A;
      border-radius: 50%;
      height: 9px;
      left: 0;
      position: absolute;
      top: 7px;
      width: 9px; }

.list-border {
  padding: 0;
  list-style: none; }
  .list-border li {
    padding: 8px 0;
    position: relative;
    border-bottom: 1px solid #ededed; }
    .list-border li strong {
      color: #03121A;
      display: inline-block;
      padding-right: 8px;
      width: 150px; }
    .list-border li span {
      display: inline-block;
      width: calc(100% - 150px); }
    .list-border li:last-child {
      border-bottom: none; }

img:focus {
  outline: none;
  outline-offset: 0; }

a {
  -webkit-transition: color, 225ms;
  -moz-transition: color, 225ms;
  transition: color, 225ms;
  color: #5484C4;
  cursor: pointer;
  text-decoration: none; }
  a:hover {
    color: #03121A;
    text-decoration: none; }
  a:focus {
    outline: none;
    outline-offset: 0; }

/* Elements */
.additional-info {
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px; }
  .additional-info p {
    color: #707070;
    margin-bottom: 8px; }
    .additional-info p:last-child {
      margin-bottom: 0; }
  .additional-info strong {
    color: #03121A; }
  .additional-info a {
    color: #5484C4; }
    .additional-info a:hover {
      text-decoration: underline; }
  @media (min-width: 767px) {
    .additional-info {
      padding: 20px; } }

.alert {
  position: relative; }
  .alert a {
    color: inherit;
    text-decoration: underline; }
  .alert .close {
    position: absolute;
    top: 16px;
    right: 12px; }

/*===================================
=            Breadcrumbs            =
===================================*/
.breadcrumb {
  background: #fff;
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  border-radius: 0;
  color: #03121A;
  font-size: 12px;
  margin-bottom: 24px;
  padding: 14px 0;
  position: relative; }
  .breadcrumb::after {
    clear: both;
    content: "";
    display: block; }
  @media (min-width: 767px) {
    .breadcrumb {
      display: block;
      border-top: 0; } }
  .breadcrumb ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0; }
    .breadcrumb ul::after {
      clear: both;
      content: "";
      display: block; }
  .breadcrumb li {
    color: #03121A;
    display: inline-block;
    font-size: 12px;
    vertical-align: middle; }
    .breadcrumb li:before {
      display: none; }
    .breadcrumb li:after {
      content: "\f054";
      speak: none;
      font-style: normal;
      font-weight: 900;
      font-family: FontAwesome;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      color: #5484C4;
      font-size: 8px;
      vertical-align: middle;
      margin: 0 8px 0 10px; }
    .breadcrumb li:last-child:after {
      display: none; }
  .breadcrumb a {
    color: #03121A;
    display: inline-block;
    font-size: 12px;
    text-decoration: none;
    vertical-align: middle; }
    .breadcrumb a:hover {
      color: #5484C4;
      text-decoration: none; }

.btn, .scw-cookie-btn {
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-shadow: none; }
  .btn, .scw-cookie-btn, .btn:hover, .scw-cookie-btn:hover, .btn:focus, .scw-cookie-btn:focus, .btn:active:hover, .scw-cookie-btn:active:hover, .btn:active, .scw-cookie-btn:active {
    outline: none;
    box-shadow: none; }
  .btn .fa, .scw-cookie-btn .fa {
    margin-right: 5px; }

.btn-primary, .scw-cookie-btn {
  color: #fff;
  background-color: #4CB944;
  border-color: #4CB944; }
  .btn-primary.active, .active.scw-cookie-btn, .btn-primary:hover, .scw-cookie-btn:hover, .btn-primary:focus, .scw-cookie-btn:focus, .btn-primary:active:hover, .scw-cookie-btn:active:hover, .btn-primary:active, .scw-cookie-btn:active {
    background-color: #309d28;
    border-color: #309d28; }

.btn-primary.active.focus, .active.focus.scw-cookie-btn,
.btn-primary.active:focus,
.active.scw-cookie-btn:focus,
.btn-primary.active:hover,
.active.scw-cookie-btn:hover,
.btn-primary:active.focus,
.scw-cookie-btn:active.focus,
.btn-primary:active:focus,
.scw-cookie-btn:active:focus,
.btn-primary:active:hover,
.scw-cookie-btn:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.focus.scw-cookie-btn,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.scw-cookie-btn:focus,
.open > .dropdown-toggle.btn-primary:hover,
.open > .dropdown-toggle.scw-cookie-btn:hover,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.scw-cookie-btn {
  background-color: #309d28;
  border-color: #309d28; }

.btn-default {
  border-color: #ededed; }
  .btn-default.active, .btn-default:hover, .btn-default:focus, .btn-default:active:hover, .btn-default:active {
    background-color: #f7f7f6;
    border-color: #ededed;
    color: #707070; }

.btn-white {
  color: #4CB944;
  background-color: #fff;
  border-color: #fff; }
  .btn-white.active, .btn-white:hover, .btn-white:focus, .btn-white:active:hover, .btn-white:active {
    background-color: #ededed;
    border-color: #ededed; }

.btn-input {
  color: #03121A;
  background-color: #fafafa;
  border-color: #ededed; }
  .btn-input.active, .btn-input:hover, .btn-input:focus, .btn-input:active:hover, .btn-input:active {
    background-color: #ededed;
    border-color: #ededed; }

.btn-group-lg > .btn, .btn-group-lg > .scw-cookie-btn,
.btn-lg {
  padding: 11px 16px; }

.btn-group-xl > .btn, .btn-group-xl > .scw-cookie-btn,
.btn-xl {
  padding: 14px 16px; }

.btn-sm {
  font-size: 12px;
  padding: 6px 20px; }
  .btn-sm.btn-icon {
    padding: 6px;
    width: 32px; }

.btn-group.open .dropdown-toggle {
  box-shadow: none; }

.scw-cookie-message a {
  color: #fff;
  text-decoration: underline; }
  .scw-cookie-message a:hover {
    text-decoration: none; }

.scw-cookie-panel-toggle {
  border: none;
  padding: 8px;
  text-align: center;
  width: 40px; }
  .scw-cookie-panel-toggle:before {
    font-size: 16px; }
  .scw-cookie-panel-toggle:before {
    content: "\f063";
    speak: none;
    font-style: normal;
    font-weight: 900;
    font-family: FontAwesome;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto; }
  .scw-cookie-panel-toggle img {
    display: none; }
  .scw-cookie-panel-toggle:hover {
    color: #4CB944; }
  .scw-cookie-slide-out .scw-cookie-panel-toggle:before {
    content: "\f062";
    speak: none;
    font-style: normal;
    font-weight: 900;
    font-family: FontAwesome;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto; }

.scw-cookie-toggle {
  border: 1px solid rgba(255, 255, 255, 0.4);
  position: relative;
  padding-right: 80px;
  width: 100%; }
  .scw-cookie-toggle .scw-cookie-name {
    width: 100%; }
  .scw-cookie-toggle p {
    margin-bottom: 0;
    padding-left: 15px; }
  .scw-cookie-toggle .scw-cookie-switch {
    position: absolute;
    top: 50%;
    width: 40px;
    transform: translateY(-50%);
    right: 20px; }
  .scw-cookie-toggle:nth-child(even) {
    margin-right: 0; }
  .scw-cookie-toggle:nth-child(odd) {
    margin-left: 0; }

.scw-cookie-switch {
  background: #f7f7f6; }
  .scw-cookie-switch.checked {
    background: #4CB944; }

/*====================================
=            Contact form            =
====================================*/
textarea, input, button {
  outline: none; }

/* Change the white to any color ;) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 34px #fafafa inset; }

input[type="password"],
input[type="reset"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="text"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.form-group .form-control,
.input-group .form-control {
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: #03121A;
  box-shadow: none; }

.form-group textarea.form-control,
.input-group textarea.form-control {
  height: 175px; }

.form-group select,
.input-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  background-image: url('data:image/svg+xml;utf8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z"/></svg>');
  background-position: calc(100% - 12px) center;
  background-size: 12px;
  background-repeat: no-repeat;
  padding-right: 1.5em; }

.form-group .form-control + a {
  display: block;
  margin-top: 4px; }

.form-control {
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: #03121A;
  box-shadow: none; }

input + .text-danger {
  display: block;
  margin-top: 4px; }

input[type=checkbox] {
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #ededed;
  box-shadow: none;
  width: 15px;
  height: 15px; }

.control-label {
  color: #03121A; }

.form-group-quantity span {
  color: #03121A;
  display: block;
  margin-top: 4px; }

.form-group-quantity .form-control {
  width: 25%;
  display: inline-block; }
  .form-group-quantity .form-control + span {
    display: inline-block;
    margin: 0 0 0 12px; }

.form-horizontal::after {
  clear: both;
  content: "";
  display: block; }

.input-group.date .fa-calendar {
  margin: 0; }

.radios {
  margin-bottom: 24px;
  margin-top: -12px; }

.checkbox-agree,
.checkbox-inline {
  margin-right: 14px;
  margin-top: 6px; }

@media (max-width: 767px) {
  .form-horizontal .checkbox,
  .form-horizontal .checkbox-inline,
  .form-horizontal .radio,
  .form-horizontal .radio-inline {
    margin-bottom: 12px; } }

fieldset {
  border-color: #ededed;
  border-radius: 4px;
  margin: -6px 0 24px;
  padding: 15px 15px 15px; }
  fieldset legend {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    color: #03121A;
    margin-bottom: 4px;
    padding: 4px 12px;
    display: inline-block;
    width: auto;
    margin-left: -12px; }
  @media (min-width: 1024px) {
    fieldset {
      padding: 15px 30px; } }

.list-group-clean .list-group-item.active {
  background: #fff;
  color: #03121A; }
  .list-group-clean .list-group-item.active:focus, .list-group-clean .list-group-item.active:hover {
    background: #fff;
    color: #5484C4; }

.list-group-clean .list-group-parent {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal; }
  .list-group-clean .list-group-parent .fa {
    color: #5484C4;
    font-size: 12px;
    margin-right: 5px; }
  .list-group-clean .list-group-parent:hover {
    color: #5484C4; }

.list-group-clean a.list-group-item:focus,
.list-group-clean a.list-group-item:hover,
.list-group-clean button.list-group-item:focus,
.list-group-clean button.list-group-item:hover {
  color: #5484C4;
  background: #fff; }

.list-group-clean .list-group-item {
  padding: 6px 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  border: none;
  color: #707070; }

.mfilter-box .mfilter-content {
  border: none; }

.mfilter-box .mfilter-heading {
  background: #fff; }
  .mfilter-box .mfilter-heading .mfilter-heading-content {
    padding: 8px 0; }
  .mfilter-box .mfilter-heading .mfilter-heading-text {
    color: #03121A;
    font-size: 16px; }
  .mfilter-box .mfilter-heading .mfilter-head-icon {
    background: none;
    color: #03121A;
    transition: none; }
    .mfilter-box .mfilter-heading .mfilter-head-icon:before {
      content: "\f078";
      speak: none;
      font-style: normal;
      font-weight: 900;
      font-family: FontAwesome;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      display: block;
      font-size: 11px; }
    .mfilter-box .mfilter-heading .mfilter-head-icon:hover {
      color: #5484C4; }
  .mfilter-box .mfilter-heading.mfilter-collapsed .mfilter-head-icon:before {
    margin-top: -6px; }

.mfilter-box .mfilter-content > ul > li.mfilter-filter-item {
  border-top: 1px solid #ededed; }

.mfilter-box .mfilter-options .mfilter-disabled label {
  color: #ccc;
  cursor: default; }

.mfilter-box .mfilter-content-opts {
  padding-bottom: 20px; }

.mfilter-box .mfilter-opts-container {
  border: none;
  padding: 0; }

.mfilter-box .mfilter-tb-as-td {
  border: none; }

.mfilter-box .mfilter-col-input input[type=checkbox], .mfilter-box .mfilter-col-input input[type=radio] {
  margin: -2px 6px 0 0; }

.mfilter-box .mfilter-filter-item .mfilter-options {
  padding: 0; }

.mfilter-box .mfilter-options label {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #03121A; }

.mfilter-box .mfilter-options .mfilter-counter {
  background: #5484C4; }
  .mfilter-box .mfilter-options .mfilter-counter:after {
    border-right-color: #5484C4; }

.mfilter-box .mfilter-button-more {
  margin-top: 6px;
  padding: 10px 0 6px;
  font-size: 12px; }

.mfilter-box .mfilter-content-opts {
  position: relative;
  display: block; }
  .mfilter-box .mfilter-content-opts .mfilter-price {
    padding-bottom: 30px; }
  .mfilter-box .mfilter-content-opts .mfilter-price-inputs {
    position: absolute;
    bottom: 20px;
    color: #fff;
    width: 100%; }
    .mfilter-box .mfilter-content-opts .mfilter-price-inputs::after {
      clear: both;
      content: "";
      display: block; }
    .mfilter-box .mfilter-content-opts .mfilter-price-inputs input {
      padding: 0;
      background: transparent;
      border: none;
      width: auto;
      box-shadow: none; }
      .mfilter-box .mfilter-content-opts .mfilter-price-inputs input:last-child {
        float: right;
        text-align: right; }

.mfilter-box .mfilter-col-count {
  display: none; }

.mfilter-box .mfilter-button-bottom {
  padding: 10px 0 6px; }

.mfilter-box .mfilter-button-reset {
  display: block;
  margin-left: 0; }
  .mfilter-box .mfilter-button-reset i {
    background: none;
    height: auto;
    margin-right: 4px;
    width: auto; }
    .mfilter-box .mfilter-button-reset i:before {
      content: "\f00d";
      speak: none;
      font-style: normal;
      font-weight: 900;
      font-family: FontAwesome;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      color: #dc1818; }

.mfilter-free-container {
  background: #fff;
  border: none;
  border-right: 1px solid #ededed;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.15);
  height: calc(100% - 45px);
  padding-left: 0;
  margin-left: -228px;
  top: 45px;
  z-index: 101; }
  .navigation--open .mfilter-free-container {
    opacity: 0; }
  .mfilter-free-container.mfilter-free-container-opened:before {
    opacity: 1;
    left: 0; }
  .mfilter-free-container:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    position: fixed;
    opacity: 0;
    transition: opacity 225ms;
    top: 0;
    visibility: hidden;
    left: -100%;
    z-index: -1; }
  .mfilter-free-container .mfilter-box {
    background: #fff;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px; }
  .mfilter-free-container .mfilter-free-button {
    background: #4CB944;
    border: none;
    width: 42px;
    height: 42px;
    color: #fff;
    line-height: 38px;
    display: block;
    text-align: center;
    font-size: 18px;
    margin-top: 0; }
    .mfilter-free-container .mfilter-free-button:before {
      content: "\f0b0";
      speak: none;
      font-style: normal;
      font-weight: 900;
      font-family: FontAwesome;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto; }

.nav-tabs {
  position: relative;
  border-bottom: 1px solid #ededed; }

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border-color: transparent;
  border-top-color: transparent;
  border-radius: 0;
  box-shadow: inset 0px -2px 0px 0px #5484C4; }

.nav-tabs > li > a {
  color: #707070; }
  .nav-tabs > li > a:hover {
    color: #5484C4;
    border-color: transparent transparent #ededed; }

.nav > li > a:focus,
.nav > li > a:hover {
  background: #fff; }

.tab-pane {
  padding: 24px 0; }

/*==================================
=            Pagination            =
==================================*/
.pagination-wrapper {
  margin-bottom: 24px; }
  @media (min-width: 767px) {
    .pagination-wrapper {
      text-align: right; } }
  .pagination-wrapper .pagination > li > a {
    color: #5484C4; }
  .pagination-wrapper .pagination > li > span,
  .pagination-wrapper .pagination > li > a {
    border-color: #ededed; }
  .pagination-wrapper .pagination > li:first-child > a,
  .pagination-wrapper .pagination > li:first-child > span {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .pagination-wrapper .pagination > li:last-child > a,
  .pagination-wrapper .pagination > li:last-child > span {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .pagination-wrapper .pagination > .active > a,
  .pagination-wrapper .pagination > .active > a:focus,
  .pagination-wrapper .pagination > .active > a:hover,
  .pagination-wrapper .pagination > .active > span,
  .pagination-wrapper .pagination > .active > span:focus,
  .pagination-wrapper .pagination > .active > span:hover {
    background-color: #5484C4;
    border-color: #5484C4; }
  .pagination-wrapper .pagination > li > a,
  .pagination-wrapper .pagination > li > span {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    height: 40px;
    padding: 9px 12px;
    text-align: center;
    width: 40px; }
  .pagination-wrapper .fa {
    font-size: 12px; }

.panel-group .panel-default {
  border-color: #ededed;
  border-left: 0;
  border-right: 0; }
  .panel-group .panel-default > .panel-heading {
    background: #f7f7f6;
    border-color: #ededed;
    color: #03121A;
    padding: 12px 15px; }
    .panel-group .panel-default > .panel-heading + .panel-collapse > .panel-body {
      border-color: #ededed;
      border-left: 0;
      border-right: 0;
      padding: 15px 15px 30px; }

.panel-group .panel + .panel {
  margin-top: -1px; }

.panel-group .panel {
  border-radius: 0;
  box-shadow: none; }

.panel-group .panel-title {
  color: #707070;
  font-size: 14px; }
  .panel-group .panel-title a {
    color: #03121A;
    display: block;
    position: relative;
    padding-right: 20px;
    text-decoration: none !important; }
    .panel-group .panel-title a .fa {
      position: absolute;
      top: 3px;
      right: 0;
      font-size: 11px;
      transition: transform 225ms; }
    .panel-group .panel-title a[aria-expanded="true"] .fa {
      transform: rotate(-180deg); }

.panel.panel-contact {
  border: none;
  box-shadow: none; }

.mfp-bg {
  opacity: 0.55; }

.mfp-zoom-out-cur {
  cursor: default; }

.mfp-close-btn-in .mfp-close,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close,
.mfp-close {
  background-color: #5484C4;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  border-radius: 0; }
  .mfp-close-btn-in .mfp-close:hover,
  .mfp-zoom-out-cur .mfp-image-holder .mfp-close:hover,
  .mfp-close:hover {
    background-color: #4CB944; }
  @media (min-width: 1024px) {
    .mfp-close-btn-in .mfp-close,
    .mfp-zoom-out-cur .mfp-image-holder .mfp-close,
    .mfp-close {
      height: 60px;
      width: 60px;
      text-align: center;
      padding: 0;
      font-size: 40px;
      line-height: 40px; } }

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  right: 0;
  top: 40px; }

button.mfp-arrow {
  opacity: 1; }
  button.mfp-arrow:before, button.mfp-arrow:after {
    display: none; }
  button.mfp-arrow .btn, button.mfp-arrow .scw-cookie-btn {
    border-radius: 0;
    background: #69686b;
    height: 50px;
    padding: 16px 0 0;
    text-align: center;
    width: 50px; }
    button.mfp-arrow .btn:before, button.mfp-arrow .scw-cookie-btn:before {
      color: #fff !important;
      font-size: 18px; }
    button.mfp-arrow .btn:hover, button.mfp-arrow .scw-cookie-btn:hover {
      background: #03121A; }
  button.mfp-arrow:active {
    margin-top: -55px; }

.white-popup {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  margin: 40px auto;
  max-width: 840px;
  padding: 35px 20px;
  position: relative;
  text-align: left; }
  .white-popup::after {
    clear: both;
    content: "";
    display: block; }
  @media (min-width: 1024px) {
    .white-popup {
      padding: 40px 35px; }
      .white-popup p {
        margin-bottom: 40px; } }
  .white-popup p, .white-popup li {
    font-size: calc(14px + 2 * ( (100vw - 320px) / 680));
    line-height: 190%; }
    @media screen and (max-width: 320px) {
      .white-popup p, .white-popup li {
        font-size: 14px; } }
    @media screen and (min-width: 1000px) {
      .white-popup p, .white-popup li {
        font-size: 16px; } }

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8; }

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

.table thead {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  background: #f7f7f6;
  color: #03121A; }

.table .img-thumbnail {
  border: none; }

.table .fa {
  margin-right: 0; }

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-color: #ededed; }

.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  background: #f7f7f6;
  color: #03121A; }

.table-bordered {
  border: 1px solid #ededed; }

.tablecart ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.tablecart li {
  padding: 12px 15px; }
  .tablecart li img {
    border: none;
    border-radius: 4px;
    max-width: 47px;
    padding: 0; }

@media (min-width: 767px) {
  .tablecart ul {
    display: flex;
    flex-wrap: wrap; }
  .tablecart li:nth-child(1) {
    width: 75px; }
  .tablecart li:nth-child(2) {
    width: 40%; }
  .tablecart li:nth-child(3) {
    width: calc(20% - 75px); }
  .tablecart li:nth-child(4) {
    width: 20%; }
  .tablecart li:nth-child(5) {
    width: 10%; }
  .tablecart li:nth-child(6) {
    text-align: right;
    width: 10%;
    padding-right: 30px; }
  .tablecart.tablecart--checkout li:nth-child(4) {
    width: 10%; }
  .tablecart.tablecart--checkout li:nth-child(5) {
    width: 20%; } }

.tablecart__header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  background: #f7f7f6;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  color: #03121A;
  display: none; }
  @media (min-width: 767px) {
    .tablecart__header {
      display: block; } }

.tablecart__body ul {
  border-bottom: 1px solid #ededed; }

.tablecart__body .input-group .fa {
  margin-right: 0; }

.tablecart__body + .tablecart__footer {
  border-top: none; }

@media (max-width: 767px) {
  .tablecart__body ul {
    position: relative; }
    .tablecart__body ul li:first-child {
      position: absolute;
      top: 0;
      left: 0; }
    .tablecart__body ul li:nth-child(2),
    .tablecart__body ul li:nth-child(3) {
      padding-left: 80px;
      padding-bottom: 0; }
    .tablecart__body ul li:nth-child(3) {
      padding-top: 0; }
    .tablecart__body ul li:nth-child(5),
    .tablecart__body ul li:nth-child(6) {
      position: relative;
      padding-left: 60%;
      padding-top: 0;
      text-align: right; }
      .tablecart__body ul li:nth-child(5):before,
      .tablecart__body ul li:nth-child(6):before {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-style: normal;
        content: attr(data-name);
        color: #03121A;
        text-align: left;
        position: absolute;
        top: 0;
        left: 15px;
        width: 60%; } }

.tablecart__footer {
  background: #f7f7f6;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin-bottom: 24px;
  padding: 4px 0; }
  .tablecart__footer::after {
    clear: both;
    content: "";
    display: block; }
  .tablecart__footer li {
    float: left;
    padding: 4px 15px; }
    .tablecart__footer li strong {
      color: #03121A; }
    .tablecart__footer li:nth-child(1) {
      width: 60%; }
    .tablecart__footer li:nth-child(2) {
      text-align: right;
      width: 40%; }
  @media (min-width: 767px) {
    .tablecart__footer li:nth-child(1) {
      margin-left: 80%;
      width: 10%; }
    .tablecart__footer li:nth-child(2) {
      width: 10%;
      padding-right: 30px; }
    .tablecart--checkout .tablecart__footer li:nth-child(1) {
      display: block;
      margin-left: 70%;
      width: 20%; }
    .tablecart--checkout .tablecart__footer li:nth-child(2) {
      text-align: right;
      width: 10%;
      padding-right: 30px; } }

/* Components */
.top {
  background: #5484C4;
  color: #fff;
  padding: 12px 0;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  overflow: hidden; }
  .top .list-inline {
    margin: 0; }
  .top a {
    color: #fff; }
  .top #top-usp li {
    padding-right: 12px; }
  @media (min-width: 767px) {
    .top {
      display: block;
      font-size: 12px; } }
  @media (min-width: 1024px) {
    .top #top-usp {
      max-width: 70%;
      overflow: hidden;
      max-height: 20px; } }
  @media (min-width: 1150px) {
    .top {
      padding: 10px 0;
      font-size: 14px; }
      .top #top-usp li {
        padding-right: 20px; } }

.header {
  background: #5484C4;
  border-bottom: 1px solid #ededed;
  position: fixed;
  top: 0;
  left: 0;
  height: 87px;
  width: 100%;
  z-index: 99; }
  .header--sticky .header {
    position: fixed;
    top: 0; }
  .header .logo-mobile {
    float: left;
    margin: 11px 0 0 0;
    text-align: center;
    width: calc(100% - 60px); }
    .header .logo-mobile a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 14px;
      color: #fff; }
  .header .shopping-cart {
    color: #fff;
    float: right;
    font-size: 20px;
    margin-top: 7px;
    position: relative; }
    .header .shopping-cart .amount {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 10px;
      line-height: 18px;
      position: absolute;
      background: #03121A;
      color: #fff;
      top: -4px;
      right: -14px;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      text-align: center; }
  @media (min-width: 767px) {
    .header {
      background: #fff;
      height: 68px;
      padding: 12px 0;
      position: absolute;
      top: 42px; }
      .header .logo {
        float: left;
        margin: 12px 0 0 6px;
        max-width: 250px; }
      .header .shopping-cart {
        background: #4CB944;
        width: 42px;
        height: 42px;
        margin: 0;
        border-radius: 4px;
        text-align: center;
        line-height: 42px; }
        .header .shopping-cart .amount {
          width: 22px;
          height: 22px;
          font-size: 12px;
          line-height: 22px;
          top: -8px;
          right: -14px; } }
  @media (min-width: 1024px) {
    .header {
      padding: 17px 0 0;
      height: calc(160px - 42px); }
      .header .shopping-cart {
        display: none; } }

body {
  padding-top: 87px; }
  @media (min-width: 767px) {
    body {
      padding-top: 110px; } }
  @media (min-width: 1024px) {
    body {
      padding-top: 160px; } }

.mobile-header-info {
  text-align: center;
  padding: 5px;
  color: #5484C4;
  display: none; }
  @media (max-width: 767px) {
    .mobile-header-info {
      display: block; } }

/*====================================================
=            Navigation trigger on mobile            =
====================================================*/
.navigation--trigger {
  background-color: transparent;
  cursor: pointer;
  display: block;
  padding: 10px 11px 0;
  position: relative;
  float: left;
  height: 45px;
  transition: all 225ms;
  text-transform: uppercase;
  vertical-align: middle;
  width: 46px;
  margin-left: -11px;
  z-index: 125;
  /*----------  Hamburger icon  ----------*/
  /* Animate the icon when open/close */ }
  .navigation--trigger span {
    border-radius: 4px;
    background-color: #fff;
    display: inline-block;
    height: 3px;
    margin: 0;
    position: relative;
    transition: background-color 150ms;
    vertical-align: middle;
    width: 24px; }
    .navigation--trigger span:before, .navigation--trigger span:after {
      background: #fff;
      border-radius: 4px;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      transition: top 150ms 250ms ease, transform 150ms ease, background-color 150ms;
      width: 24px; }
    .navigation--trigger span:before {
      top: -7px; }
    .navigation--trigger span:after {
      top: 7px; }
  .navigation--open .navigation--trigger span {
    background-color: transparent !important; }
    .navigation--open .navigation--trigger span:before {
      top: 0;
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 150ms ease, transform 175ms 250ms ease, background-color 150ms; }
    .navigation--open .navigation--trigger span:after {
      top: 0;
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 150ms ease, transform 175ms 250ms ease, background-color 150ms; }
  @media (min-width: 767px) {
    .navigation--trigger span {
      background-color: #5484C4; }
      .navigation--trigger span:before, .navigation--trigger span:after {
        background: #5484C4; }
    .navigation--open .navigation--trigger {
      top: -55px; }
      .navigation--open .navigation--trigger span {
        background-color: #fff; }
        .navigation--open .navigation--trigger span:before, .navigation--open .navigation--trigger span:after {
          background: #fff; } }
  @media (min-width: 1024px) {
    .navigation--trigger {
      display: none; } }

.header .search {
  float: left;
  margin: 0 -15px;
  padding: 0;
  width: calc(100% + 30px); }
  .header .search p {
    display: none; }
  .header .search input {
    border-radius: 0;
    background-color: #fff;
    font-size: 14px;
    height: 42px; }
  .header .search .btn, .header .search .scw-cookie-btn {
    background: #03121A;
    border-color: #03121A;
    border-radius: 0;
    color: #fff;
    font-size: 20px;
    height: 42px;
    padding: 0 6px 0 11px; }
    .header .search .btn .fa, .header .search .scw-cookie-btn .fa {
      display: inline-block;
      margin-top: -2px; }
    .header .search .btn:focus, .header .search .scw-cookie-btn:focus, .header .search .btn.active, .header .search .active.scw-cookie-btn, .header .search .btn:active, .header .search .scw-cookie-btn:active, .header .search .btn:hover, .header .search .scw-cookie-btn:hover {
      border-color: #5484C4;
      background: #5484C4; }
  @media (max-width: 767px) {
    .has-filter .header .search {
      padding-left: 42px; } }
  @media (min-width: 767px) {
    .header .search {
      float: right;
      margin-right: 18px;
      width: 350px; }
      .header .search.input-group input.form-control {
        border-radius: 4px 0 0 4px; }
      .header .search .btn, .header .search .scw-cookie-btn {
        border-radius: 0 4px 4px 0; } }
  @media (min-width: 1024px) {
    .header .search {
      max-width: 500px;
      width: 100%; }
      .header .search .form-control {
        max-width: 300px;
        vertical-align: top;
        float: left; }
      .header .search .input-group-btn {
        vertical-align: top;
        padding: 0;
        float: left; }
      .header .search p {
        color: #03121A;
        display: inline-block;
        float: left;
        margin-right: 28px;
        text-align: right; }
        .header .search p a {
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          font-style: normal;
          color: #4CB944; }
          .header .search p a:hover {
            color: #4CB944;
            text-decoration: none; } }

#cart {
  display: none;
  float: right; }
  #cart .btn, #cart .scw-cookie-btn {
    position: relative; }
    #cart .btn .total, #cart .scw-cookie-btn .total {
      position: absolute;
      background: #03121A;
      width: 24px;
      height: 24px;
      right: -12px;
      top: -12px;
      border-radius: 50%;
      text-align: center;
      line-height: 24px; }
  #cart .dropdown-menu {
    min-width: 300px;
    padding: 0; }
    #cart .dropdown-menu table {
      margin-bottom: 0; }
    #cart .dropdown-menu .cart-products a {
      color: #03121A; }
    #cart .dropdown-menu .btn-danger .fa {
      padding: 4px 1px;
      display: block;
      margin: 0; }
    #cart .dropdown-menu img {
      min-height: 47px;
      min-width: 47px;
      border: none;
      border-radius: 4px; }
  #cart .totals {
    background-color: #f7f7f6;
    color: #03121A; }
  #cart .actions {
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    padding: 8px;
    border-top: 1px solid #ededed; }
  #cart .empty-cart {
    padding: 8px; }
  @media (min-width: 1024px) {
    #cart {
      display: inline-block; } }

.navigation .container {
  position: relative; }

.navigation .navbar {
  margin-bottom: 0; }

.navigation .mn-link.active > a {
  color: #5484C4; }

@media (min-width: 1024px) {
  .navigation .top-links {
    font-size: 12px;
    padding: 12px 0 0;
    position: absolute;
    right: 15px;
    top: -116px; }
    .navigation .top-links ul {
      margin: 0; }
    .navigation .top-links li {
      padding: 0 0 0 20px; }
    .navigation .top-links a {
      color: #fff; }
      .navigation .top-links a:hover {
        text-decoration: underline; } }

@media (min-width: 1150px) {
  .navigation .top-links {
    padding-top: 10px;
    font-size: 14px; } }

@media (max-width: 1023px) {
  body.navigation--open {
    overflow: hidden;
    position: fixed;
    width: 100%; }
    body.navigation--open .navigation {
      left: 0; }
  .mn-indicator,
  .mn-button {
    display: none; }
  .navigation {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    left: -100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    transition: left 225ms;
    padding-right: 42px;
    z-index: 111; }
    .navigation .container {
      display: flex;
      flex-direction: column;
      height: calc(100% - 42px);
      background: #fff;
      overflow-x: hidden;
      overflow-y: auto; }
    .navigation .mn-title {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-style: normal;
      background-color: #5484C4;
      color: #fff;
      height: 42px;
      line-height: 42px;
      text-align: center; }
    .navigation a {
      display: block;
      padding: 6px 0; }
    .navigation ul {
      margin: 0; }
  .mn-indicator {
    border-bottom: 1px solid #fff;
    margin: -1px -15px 0;
    position: relative;
    padding: 0 15px; }
  .mn-link {
    border-bottom: 1px solid #ededed;
    margin: 0 -15px;
    position: relative;
    padding: 0 15px; }
    .mn-link:last-of-type {
      border-bottom: none; }
    .mn-link a {
      color: #03121A; }
    .mn-link span {
      position: absolute;
      top: 7px;
      font-size: 12px;
      right: 15px;
      color: #5484C4; }
      .mn-link span.toggle--subnav--open {
        transform: rotate(-180deg); }
    .mn-link.subnav--open ul {
      max-height: 500px;
      padding-bottom: 6px; }
    .mn-link ul {
      max-height: 0;
      overflow: hidden;
      transition: max-height 225ms; }
  .top-links {
    background-color: #f7f7f6;
    margin: 26px -15px 0;
    padding: 0 15px; }
    .top-links ul {
      margin: 8px 0; }
    .top-links li {
      position: relative;
      margin: 0 -15px;
      padding: 0 15px;
      width: calc(100% + 30px); }
      .top-links li:last-child {
        border-bottom: 0; }
    .top-links a {
      color: #03121A; }
    .top-links li.active > a {
      color: #5484C4; } }

@media (max-width: 767px) {
  .navigation .container {
    height: calc(100% - 45px); }
  .navigation .mn-title {
    height: 45px;
    line-height: 45px; } }

@media (min-width: 1024px) {
  .navigation {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .mn-title {
    display: none; }
  .mainnav {
    position: relative;
    padding: 0;
    box-sizing: border-box;
    z-index: 101; }
  .mn-nav {
    overflow-x: hidden;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    position: relative;
    font-size: 0; }
    .js .mn-nav {
      -ms-overflow-style: -ms-autohiding-scrollbar; }
      .js .mn-nav::-webkit-scrollbar {
        display: none; }
  .mn-content {
    float: left;
    transition: transform .2s ease-in-out;
    position: relative;
    margin: 0;
    padding: 0; }
  .mn-no-transition {
    transition: none; }
  .mn-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    min-height: 44px;
    padding: 0 20px 0 0; }
    .mn-link a {
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      font-style: normal;
      color: #03121A;
      text-decoration: none;
      font-size: 14px; }
      .mn-link a:hover {
        color: #5484C4; }
    .mn-link span,
    .mn-link ul {
      display: none; }
  .mn-button {
    /* Reset the button */
    appearance: none;
    background: #5484C4;
    padding: 0;
    color: #fff;
    border: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: 14px;
    line-height: 22px;
    font-size: 12px;
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity .3s;
    visibility: hidden; }
    .mn-button:hover {
      cursor: pointer;
      background-color: #4CB944; }
  .mn-button-left {
    padding-right: 1px;
    left: -2px; }
    [data-overflowing="both"] ~ .mn-button-left,
    [data-overflowing="left"] ~ .mn-button-left {
      opacity: 1;
      visibility: visible; }
  .mn-button-right {
    padding-left: 3px;
    right: -2px; }
    [data-overflowing="both"] ~ .mn-button-right,
    [data-overflowing="right"] ~ .mn-button-right {
      visibility: visible;
      opacity: 1; }
  .mn-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100px;
    background-color: transparent;
    transform-origin: 0 0;
    transition: transform .2s ease-in-out, background-color .2s ease-in-out; } }

.footer__top {
  background: #5484C4;
  color: #fff;
  padding: 24px 0 0;
  margin-top: 24px; }
  .footer__top h4 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 4px; }
  .footer__top a {
    color: #fff; }
    .footer__top a:hover {
      color: #fff;
      text-decoration: underline; }
  .footer__top strong {
    display: block; }
  .footer__top span {
    font-size: 12px; }
  .footer__top .col-sm-3 a:hover {
    text-decoration: none; }
  @media (min-width: 767px) {
    .footer__top {
      margin-top: 36px;
      padding: 48px 0 0; }
      .footer__top h4 {
        margin-bottom: 23px; } }
  @media (min-width: 1150px) {
    .footer__top {
      margin-top: 50px; } }

.footer__block {
  margin-bottom: 24px; }
  @media (min-width: 767px) {
    .footer__block {
      float: left;
      margin-bottom: 40px;
      width: 50%; } }
  @media (min-width: 1024px) {
    .footer__block {
      /* one item */
      /* two items */
      /* three items */
      /* four items */ }
      .footer__block:first-child:nth-last-child(1) {
        width: 100%; }
      .footer__block:first-child:nth-last-child(2),
      .footer__block:first-child:nth-last-child(2) ~ .footer__block {
        width: 50%; }
      .footer__block:first-child:nth-last-child(3),
      .footer__block:first-child:nth-last-child(3) ~ .footer__block {
        width: 33.3333%; }
      .footer__block:first-child:nth-last-child(4),
      .footer__block:first-child:nth-last-child(4) ~ .footer__block {
        width: 25%; } }

.footer__block--contact {
  width: 100%; }
  .footer__block--contact h4 {
    margin-bottom: 8px; }
  .footer__block--contact div {
    position: relative;
    margin-bottom: 12px;
    padding-left: 35px;
    line-height: 1.2; }
    .footer__block--contact div:last-child {
      margin-bottom: 0; }
    .footer__block--contact div i {
      position: absolute;
      left: 0; }
  .footer__block--contact .phone i {
    font-size: 28px;
    top: 4px; }
  .footer__block--contact .mail i {
    font-size: 20px;
    top: 5px; }
  .footer__block--contact .whatsapp i {
    font-size: 28px;
    top: 4px; }
  @media (min-width: 767px) {
    .footer__block--contact h4 {
      margin-bottom: 24px; }
    .footer__block--contact div {
      line-height: 1.5; }
    .footer__block--contact .mail i {
      top: 8px; } }
  .footer__block--contact .socials {
    width: 100%;
    display: flex; }
    .footer__block--contact .socials a {
      display: block;
      margin: 15px 15px 0 0; }
    .footer__block--contact .socials i {
      position: static;
      font-size: 25px; }

/*===========================================
=            White footer bottom            =
===========================================*/
.footer__bottom {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-style: normal;
  background: #fff;
  color: #707070;
  padding: 20px 0 32px;
  text-align: center; }
  .footer__bottom a {
    color: #707070; }
    .footer__bottom a:hover {
      color: #5484C4; }
  @media (min-width: 1024px) {
    .footer__bottom {
      padding-bottom: 60px; } }
  @media (max-width: 767px) {
    .footer__bottom .footer__meta li {
      display: block; } }

/*----------  Footer logo`s  ----------*/
.footer__icons {
  margin-bottom: 16px; }
  .footer__icons li {
    padding: 5px; }
  @media (min-width: 767px) {
    .footer__icons li {
      padding: 12px; } }

/*----------  Footer url`s  ----------*/
.footer__meta ul {
  margin-bottom: 6px; }
  .footer__meta ul li {
    padding: 0 7px; }

/*----------  Feedback company  ----------*/
.feedback-company a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #4CB944; }
  .feedback-company a:hover {
    color: #4CB944;
    text-decoration: underline; }

.blogpost {
  position: relative;
  margin-top: -25px; }
  .blogpost .blogpost__header {
    margin-bottom: 25px; }
  .blogpost .blogpost__content {
    background: #fff; }
  .blogpost .blogpost__meta {
    margin-bottom: 8px; }
    .blogpost .blogpost__meta span {
      display: block; }
    .blogpost .blogpost__meta .fa {
      color: #707070;
      margin-right: 4px; }
    .blogpost .blogpost__meta a {
      color: #707070;
      text-decoration: underline; }
      .blogpost .blogpost__meta a:hover {
        text-decoration: none; }
  .blogpost .title {
    font-size: calc(24px + 8 * ( (100vw - 320px) / 680));
    color: #5484C4; }
    @media screen and (max-width: 320px) {
      .blogpost .title {
        font-size: 24px; } }
    @media screen and (min-width: 1000px) {
      .blogpost .title {
        font-size: 32px; } }
    .blogpost .title + p {
      font-size: calc(16px + 2 * ( (100vw - 320px) / 680)); }
      @media screen and (max-width: 320px) {
        .blogpost .title + p {
          font-size: 16px; } }
      @media screen and (min-width: 1000px) {
        .blogpost .title + p {
          font-size: 18px; } }
  .blogpost h1:not(.title), .blogpost h2, .blogpost h3, .blogpost h4, .blogpost h5, .blogpost h6 {
    margin-bottom: 4px; }
  .blogpost h2, .blogpost h3, .blogpost h4, .blogpost h5, .blogpost h6 {
    color: #707070; }
  .blogpost h1:not(.title) {
    font-size: calc(20px + 4 * ( (100vw - 320px) / 680)); }
    @media screen and (max-width: 320px) {
      .blogpost h1:not(.title) {
        font-size: 20px; } }
    @media screen and (min-width: 1000px) {
      .blogpost h1:not(.title) {
        font-size: 24px; } }
  .blogpost h2 {
    font-size: calc(18px + 2 * ( (100vw - 320px) / 680)); }
    @media screen and (max-width: 320px) {
      .blogpost h2 {
        font-size: 18px; } }
    @media screen and (min-width: 1000px) {
      .blogpost h2 {
        font-size: 20px; } }
  .blogpost h3 {
    font-size: 16px; }
  @media (min-width: 767px) {
    .blogpost .blogpost__header--empty {
      height: 145px;
      background: #5484C4; }
    .blogpost .blogpost__content {
      background: #fff;
      margin: -80px 20px 0;
      padding: 32px 46px;
      position: relative;
      width: calc(100% - 40px); }
    .blogpost .blogpost__meta span {
      display: inline-block;
      margin-right: 8px; } }
  @media (min-width: 1024px) {
    .blogpost .blogpost__content {
      width: 900px;
      margin: -85px auto 0;
      padding: 40px 100px 0; }
    .blogpost .blogpost__header--empty + .blogpost__content {
      margin-top: -105px;
      padding-top: 50px; } }

.slideshow {
  border-radius: 4px;
  margin: 24px 0;
  position: relative; }
  .slideshow .slick-arrow {
    color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 50%;
    transition: color 225ms;
    transform: translateY(-50%);
    z-index: 1; }
    .slideshow .slick-arrow:hover {
      color: #fff; }
    .slideshow .slick-arrow i:before {
      font-size: 20px;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24); }
    .slideshow .slick-arrow.slick-left {
      left: 10px; }
    .slideshow .slick-arrow.slick-right {
      right: 10px; }
  .slideshow .slide {
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    color: #fff;
    height: 325px;
    text-align: center;
    overflow: hidden;
    display: flex;
    padding: 0 30px;
    justify-content: center;
    align-items: center; }
  .slideshow h3 {
    font-size: calc(22px + 10 * ( (100vw - 320px) / 680));
    color: #fff;
    margin-bottom: 18px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24); }
    @media screen and (max-width: 320px) {
      .slideshow h3 {
        font-size: 22px; } }
    @media screen and (min-width: 1000px) {
      .slideshow h3 {
        font-size: 32px; } }
  .slideshow p {
    font-size: calc(13px + 2 * ( (100vw - 320px) / 680));
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.64);
    margin-bottom: 42px; }
    @media screen and (max-width: 320px) {
      .slideshow p {
        font-size: 13px; } }
    @media screen and (min-width: 1000px) {
      .slideshow p {
        font-size: 15px; } }
    .slideshow p:last-child {
      margin-bottom: 0; }
  @media (min-width: 767px) {
    .slideshow .slick-arrow i:before {
      font-size: 36px; }
    .slideshow .slick-arrow.slick-left {
      left: 20px; }
    .slideshow .slick-arrow.slick-right {
      right: 20px; }
    .slideshow .slide {
      height: 275px;
      padding: 0 60px; } }

@media (min-width: 1024px) {
  .cta__wrapper {
    margin-bottom: 24px; }
    .cta__wrapper .row {
      display: flex; } }

.cta {
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 150px;
  margin-bottom: 24px;
  position: relative; }
  .cta h4 {
    color: #fff;
    font-size: 22px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
    margin-bottom: 18px; }
    .cta h4 span {
      font-weight: 400; }
  @media (min-width: 1024px) {
    .cta {
      margin-bottom: 0; } }

.cta--action {
  padding: 20px 48px 48px 20px; }
  .cta--action .action {
    background: #f39300;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
    line-height: 84px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    transform: rotate(12deg);
    position: absolute;
    top: -4px;
    right: -4px; }
  .cta--action .btn, .cta--action .scw-cookie-btn {
    position: absolute;
    bottom: 10px;
    right: 10px; }
    .cta--action .btn .fa, .cta--action .scw-cookie-btn .fa {
      margin-right: 0; }
  @media (min-width: 767px) {
    .cta--action .action {
      display: block;
      right: 32px;
      top: 6px; }
    .cta--action h4 {
      position: absolute;
      max-width: 70%;
      width: 100%;
      bottom: 10px;
      left: 20px; }
    .cta--action .btn, .cta--action .scw-cookie-btn {
      bottom: 20px;
      right: 20px; } }

.cta--offer {
  text-align: center;
  padding: 20px; }

.cta--reviews {
  border: 3px solid #4cb944;
  padding: 30px 0 30px 20px; }
  .cta--reviews .number {
    background-image: url("/catalog/view/theme/be/assets/images/kiyoh.png");
    background-size: 50px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin-right: 4px;
    width: 50px;
    vertical-align: middle; }
  .cta--reviews .rating {
    display: inline-block;
    height: 24px;
    position: relative;
    vertical-align: middle;
    width: 150px; }
    .cta--reviews .rating i {
      position: absolute;
      top: 0;
      left: 0; }
      .cta--reviews .rating i:nth-child(2) {
        left: 30px; }
      .cta--reviews .rating i:nth-child(3) {
        left: 60px; }
      .cta--reviews .rating i:nth-child(4) {
        left: 90px; }
      .cta--reviews .rating i:nth-child(5) {
        left: 120px; }
  .cta--reviews .rating--top,
  .cta--reviews .rating--bottom {
    position: absolute;
    top: 0;
    font-size: 26px;
    left: 0;
    width: 150px;
    height: 24px;
    overflow: hidden; }
  .cta--reviews .rating--top {
    color: #edb719;
    z-index: 1; }
  .cta--reviews .rating--bottom {
    color: #fae8ba;
    z-index: 0; }
  .cta--reviews a {
    color: #03121A;
    display: block;
    font-weight: bold;
    margin-top: 12px; }
    .cta--reviews a:hover {
      color: #03121A;
      text-decoration: underline; }

.category {
  border-radius: 4px;
  border: 1px solid #ededed;
  margin-bottom: 24px;
  padding-bottom: 30px;
  position: relative; }
  .category:hover .category__title {
    background: #3e6395; }
  .category img {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
    display: block;
    padding: 8px 15px;
    width: 100%; }
  .category__title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    background: #5484C4;
    border-radius: 0 0 4px 4px;
    bottom: 0;
    color: #fff;
    left: 0;
    font-size: 11px;
    padding: 8px 12px;
    position: absolute;
    text-align: center;
    transition: background 225ms;
    width: 100%; }
  .category a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  @media (min-width: 767px) {
    .category {
      padding-bottom: 40px; }
      .category__title {
        font-size: 14px;
        padding: 12px; } }

.categories .col-xs-6 {
  margin-top: 0; }
  .categories .col-xs-6:nth-child(odd) {
    padding-right: 4px; }
  .categories .col-xs-6:nth-child(even) {
    padding-left: 4px; }
  @media (min-width: 767px) {
    .categories .col-xs-6:nth-child(odd) {
      padding-right: 15px; }
    .categories .col-xs-6:nth-child(even) {
      padding-left: 15px; } }

.post {
  align-items: center;
  background-size: cover;
  background-position: center;
  background-color: #5484C4;
  border-radius: 4px;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 275px;
  margin-bottom: 24px;
  text-align: center;
  padding: 20px; }
  .post__type {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: calc(14px + 2 * ( (100vw - 320px) / 680));
    margin-bottom: 11px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
    text-transform: uppercase; }
    @media screen and (max-width: 320px) {
      .post__type {
        font-size: 14px; } }
    @media screen and (min-width: 1000px) {
      .post__type {
        font-size: 16px; } }
  .post__title {
    font-size: calc(18px + 6 * ( (100vw - 320px) / 680));
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24);
    margin-bottom: 24px; }
    @media screen and (max-width: 320px) {
      .post__title {
        font-size: 18px; } }
    @media screen and (min-width: 1000px) {
      .post__title {
        font-size: 24px; } }
  .post .btn, .post .scw-cookie-btn {
    min-width: 160px; }
  @media (min-width: 767px) {
    .post {
      min-height: 375px; } }
  @media (min-width: 1024px) {
    .post {
      min-height: 515px; } }

@media (max-width: 767px) {
  .post--slider {
    clear: both;
    margin-bottom: 24px; }
    .post--slider .slick-arrow {
      color: rgba(255, 255, 255, 0.6);
      position: absolute;
      top: 50%;
      transition: color 225ms;
      transform: translateY(-50%);
      z-index: 1; }
      .post--slider .slick-arrow:hover {
        color: #fff; }
      .post--slider .slick-arrow i:before {
        font-size: 20px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.24); }
      .post--slider .slick-arrow.slick-left {
        left: 20px; }
      .post--slider .slick-arrow.slick-right {
        right: 20px; }
    .post--slider .col-sm-6 + .col-sm-6 {
      margin-top: 0; }
    .post--slider .post {
      min-height: 350px;
      margin: 0; } }

/* Open cart */
#common-maintenance {
  padding-top: 24px; }
  @media (min-width: 767px) {
    #common-maintenance {
      padding-top: 42px; }
      #common-maintenance .p-large {
        margin-top: -10px;
        margin-bottom: 24px; } }

.address {
  border-radius: 4px;
  border: 1px solid #ededed;
  padding: 15px;
  margin-bottom: 24px;
  position: relative; }
  .address.address-default {
    border-color: #5484C4; }

@media (min-width: 767px) {
  .addresses .col-sm-6 {
    margin-bottom: 24px; }
  .addresses .btn-group {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: calc(100% -30px); }
  .addresses .address {
    margin-bottom: 0;
    padding: 20px 20px 50px 20px;
    height: 100%; } }

.manufacturer {
  border-top: 1px solid #ededed;
  margin-bottom: 24px;
  padding-top: 24px; }

.modules {
  margin-top: 24px; }

.introduction {
  color: #707070;
  margin-bottom: 35px;
  line-height: 1.7; }
  .introduction .title {
    margin-top: 0;
    text-align: left; }

.category-filter .list-unstyled {
  margin: 0 auto 20px; }
  .category-filter .list-unstyled li {
    display: inline-block; }
    .category-filter .list-unstyled li:after {
      content: '|';
      margin: 0 4px 0 8px;
      font-size: 11px;
      vertical-align: middle; }
    .category-filter .list-unstyled li:last-child:after {
      display: none; }
    .category-filter .list-unstyled li a {
      color: #5484C4; }
      .category-filter .list-unstyled li a:hover {
        color: #03121A; }
  .category-filter .list-unstyled strong {
    color: #03121A; }

.category-filter .form-group {
  width: 100%; }

.category-filter .form-control {
  height: 42px;
  padding: 8px 12px; }

@media (min-width: 767px) {
  .category-filter .list-unstyled {
    margin: 14px auto 0; } }

@media (min-width: 1024px) {
  .category-filter .form-group {
    width: auto;
    float: right; } }

#shipping-existing,
#payment-existing {
  margin-top: 6px;
  margin-bottom: 6px; }

.price strong {
  font-size: 16px;
  margin-right: 4px; }

.price-tax {
  color: #707070;
  font-size: 12px; }

.price-old {
  text-decoration: line-through; }

.price-new {
  color: #e63935; }

.product-thumb {
  border-radius: 4px;
  border: 1px solid #ededed;
  height: 100%;
  margin-bottom: 24px;
  padding-bottom: 42px;
  position: relative; }
  .product-thumb .product-sale {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-style: normal;
    background: #e63935;
    bottom: 0;
    color: #fff;
    left: 0;
    font-size: 12px;
    padding: 4px 16px;
    position: absolute; }
  .product-thumb .image {
    position: relative; }
    .product-thumb .image a {
      display: block; }
    .product-thumb .image img {
      margin: 6px auto 0; }
  .product-thumb .caption {
    padding: 12px 16px 64px 16px; }
    .product-thumb .caption h4 {
      font-size: 16px;
      margin-bottom: 6px; }
    .product-thumb .caption a {
      color: #03121A; }
      .product-thumb .caption a:hover {
        color: #5484C4; }
    .product-thumb .caption dl {
      margin: 0; }
      .product-thumb .caption dl::after {
        clear: both;
        content: "";
        display: block; }
      .product-thumb .caption dl dt, .product-thumb .caption dl dd {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-style: normal;
        color: #707070;
        float: left;
        margin: 0;
        line-height: 150%;
        width: 100px; }
      .product-thumb .caption dl dt {
        clear: left; }
      .product-thumb .caption dl dd {
        width: calc(100% - 100px);
        padding-left: 6px; }
  .product-thumb .price {
    bottom: 65px;
    color: #03121A;
    left: 0;
    position: absolute;
    padding: 0 15px 0 15px;
    width: 100%; }
    .product-thumb .price p {
      color: #707070;
      font-size: 12px; }
  .product-thumb .button-group {
    bottom: -1px;
    left: -1px;
    position: absolute;
    width: calc(100% + 2px);
    display: table; }
    .product-thumb .button-group .input-group {
      padding: 0; }
      .product-thumb .button-group .input-group .form-control {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-style: normal;
        background: #fafafa;
        font-size: 14px;
        height: 42px;
        padding: 8px 16px;
        border-radius: 0 0 0 4px; }
    .product-thumb .button-group .btn, .product-thumb .button-group .scw-cookie-btn, .product-thumb .button-group .input-group {
      display: table-cell;
      vertical-align: bottom; }
    .product-thumb .button-group .btn, .product-thumb .button-group .scw-cookie-btn {
      border-radius: 0 0 4px 0;
      width: 100%; }

@media (max-width: 767px) {
  .product__wrapper .row {
    overflow: hidden;
    clear: both; } }

@media (max-width: 767px) {
  .product-layout {
    margin: 0 -15px;
    width: calc(100% + 30px); }
    .product-layout .product-thumb {
      border-left: none;
      border-right: 0;
      border-radius: 0;
      margin: -25px 0 0 0;
      padding: 0 20px 20px 30%; }
      .product-layout .product-thumb::after {
        clear: both;
        content: "";
        display: block; }
    .product-layout .image {
      position: absolute;
      top: 20px;
      left: 20px;
      width: 25%; }
    .product-layout .caption {
      padding-bottom: 0; }
    .product-layout .price {
      bottom: auto;
      margin-top: 16px;
      position: relative; }
    .product-layout .button-group {
      bottom: auto;
      margin-top: 10px;
      position: relative;
      width: auto; }
      .product-layout .button-group input,
      .product-layout .button-group .btn,
      .product-layout .button-group .scw-cookie-btn {
        border-radius: 0; }
      .product-layout .button-group .btn, .product-layout .button-group .scw-cookie-btn {
        min-width: 65px; }
        .product-layout .button-group .btn span, .product-layout .button-group .scw-cookie-btn span {
          display: none; }
    .product-layout:first-child .product-thumb {
      margin-top: 0; } }

@media (min-width: 767px) {
  .product-layout {
    margin-bottom: 24px; } }

.product-product .price {
  margin-bottom: 24px; }

.product-product .form-group .form-control,
.product-product .input-group .form-control {
  height: 50px; }

.toggle-discounts {
  margin-left: 6px; }
  .toggle-discounts:hover {
    color: #5484C4;
    text-decoration: underline; }

.discounts {
  overflow: hidden;
  max-height: 0;
  transition: max-height 225ms; }
  .discounts.discounts-open {
    max-height: 500px; }

.amount {
  font-size: 12px; }

.tax {
  display: block; }
  .tax strong {
    font-size: 12px; }

.stock {
  float: left;
  margin: 8px 0 30px;
  padding-left: 30px;
  position: relative;
  width: 100%; }
  .stock--green {
    color: #18b100; }
  .stock--orange {
    color: #f39300; }
  .stock--red {
    color: #dc1818; }
  .stock i {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 24px; }

.product-nav-tabs {
  margin-top: 35px; }
  .product-nav-tabs li a {
    padding: 10px 0; }
  .product-nav-tabs + .tab-content ul {
    margin-bottom: 18px;
    padding-left: 18px; }
  .product-nav-tabs + .tab-content h4 {
    margin-bottom: 20px; }
  @media (min-width: 767px) {
    .product-nav-tabs li:nth-child(1) a {
      margin-left: 0; }
    .product-nav-tabs li a {
      margin: 0 15px; } }

.product-gallery .sale {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-style: normal;
  background: #e63935;
  border-radius: 0 4px 0 0;
  top: 0;
  color: #fff;
  right: 15px;
  font-size: 12px;
  padding: 4px 16px;
  position: absolute; }

.product-description .EZ-base {
  display: none; }

.specifications__table {
  margin-bottom: 22px; }
  .specifications__table dl {
    margin: 0;
    padding: 8px 15px; }
    .specifications__table dl::after {
      clear: both;
      content: "";
      display: block; }
    .specifications__table dl:nth-child(odd) {
      background: #f7f7f6; }
  .specifications__table dd, .specifications__table dt {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    float: left; }
  .specifications__table dt {
    width: 40%; }
  .specifications__table dd {
    width: 60%; }

.productslider-for {
  display: none;
  margin-bottom: 24px;
  border: 1px solid #ededed;
  border-radius: 4px; }
  .productslider-for .slick-track {
    display: flex; }
    .productslider-for .slick-track .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center; }
  .productslider-for .video-wrapper {
    width: 100%; }
  .productslider-for .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden; }
    .productslider-for .video-container iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: none; }
  .productslider-for .slick-slide > div {
    display: inline-block; }
  .productslider-for img {
    display: block;
    margin: 0 auto;
    padding: 0 50px; }
  .productslider-for .slick-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    cursor: pointer;
    display: block;
    z-index: 8;
    margin-top: -14px;
    background: #69686b;
    width: 40px;
    padding-left: 2px;
    height: 40px;
    text-align: center; }
    .productslider-for .slick-arrow:hover {
      background: #03121A; }
    .productslider-for .slick-arrow .fa {
      color: #fff;
      font-size: 16px;
      line-height: 40px; }
    .productslider-for .slick-arrow.slick-left {
      left: 0;
      padding: 0 2px 0 0;
      right: auto; }
  @media (min-width: 767px) {
    .productslider-for {
      margin-bottom: 10px; } }

.productslider-nav {
  display: none;
  margin-left: -5px;
  margin-right: -5px; }
  .productslider-nav .slick-track {
    display: flex; }
    .productslider-nav .slick-track .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center; }
  @media (max-width: 767px) {
    .productslider-nav {
      display: none !important; } }
  .productslider-nav.productslide .slick-track {
    width: auto !important; }
  .productslider-nav .product__video {
    position: relative; }
    .productslider-nav .product__video:before {
      content: "\f144";
      speak: none;
      font-style: normal;
      font-weight: 900;
      font-family: FontAwesome;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      border-radius: 50%;
      line-height: 38px;
      background: #fff;
      color: #03121A;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 45px; }
  .productslider-nav .product__video div,
  .productslider-nav .product__image div {
    border: 1px solid #ededed;
    border-radius: 4px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    width: calc(100% - 10px); }
  .productslider-nav .product__image.slick-current img {
    border-color: #1b2227; }

.search-filter {
  background: #f7f7f6;
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-bottom: 24px;
  padding: 15px; }
  .search-filter::after {
    clear: both;
    content: "";
    display: block; }
  .search-filter select, .search-filter input[type="text"] {
    background: #fff; }
  .search-filter .btn, .search-filter .scw-cookie-btn {
    margin-top: 24px; }
  @media (min-width: 767px) {
    .search-filter {
      padding: 15px 30px 20px; }
      .search-filter .btn, .search-filter .scw-cookie-btn {
        margin-top: 0; } }

/* Print */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important; }
  body {
    background: none;
    color: #000;
    line-height: 1.2em;
    font-family: Garamond,"Times New Roman", serif;
    font-size: 11pt;
    width: 95%;
    margin: 0 auto;
    word-spacing: 1px; }
  html body a:after {
    content: " ( " attr(href) " )";
    text-transform: lowercase !important;
    font-family: Arial, sans-serif !important;
    font-size: 11pt !important; }
  /* Headings */
  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid; }
  h1 {
    font-size: 19pt; }
  h2 {
    font-size: 17pt; }
  h3 {
    font-size: 15pt; }
  h4, h5, h6 {
    font-size: 14pt; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  code {
    font: 12pt Courier, monospace; }
  blockquote, pre {
    margin: 1.2em;
    padding: 1em;
    font-size: 12pt;
    page-break-inside: avoid;
    border: 1px solid #999; }
  hr {
    background-color: #ccc; }
  /* Images */
  img {
    float: left;
    margin: 1em 1.5em 1.5em 0;
    max-width: 100% !important; }
  a img {
    border: none; }
  /* Links */
  a:link, a:visited {
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    color: #333; }
  a:link[href^="http://"]:after, a[href^="http://"]:visited:after {
    content: " (" attr(href) ") ";
    font-size: 90%; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /* Don't show linked images  */
  a[href^="http://"] {
    color: #000; }
  a[href$=".jpg"]:after, a[href$=".jpeg"]:after, a[href$=".gif"]:after, a[href$=".png"]:after {
    content: " (" attr(href) ") ";
    display: none; }
  /* Don't show links that are fragment identifiers, or use the `javascript:` pseudo protocol .. taken from html5boilerplate */
  .ir a:after, a[href^="#"]:after, a[href^="javascript:"]:after {
    content: ""; }
  /* Table */
  table {
    margin: 1px;
    text-align: left; }
  th {
    border-bottom: 1px solid #333;
    font-weight: bold; }
  td {
    border-bottom: 1px solid #333; }
  th, td {
    padding: 4px 10px 4px 0; }
  tfoot {
    font-style: italic; }
  caption {
    background: #fff;
    margin-bottom: 2em;
    text-align: left; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  .hide-print {
    display: none !important; } }
