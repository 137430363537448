#common-maintenance {
	padding-top: 24px;

	@include breakpoint(tablet) {
		padding-top: 42px;

		.p-large {
			margin-top: -10px;
			margin-bottom: 24px;
		}
	}
}