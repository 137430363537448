.table {
	thead {
		@include baseFont(600);
		background: $grayBg;
		color: $fontColor;
	}

	.img-thumbnail {
		border: none;
	}

	.fa {
		margin-right: 0;
	}
}

.table>tbody>tr>td, 
.table>tbody>tr>th, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th {
	border-color: $borderColor;
}

.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th {
	background: $grayBg;
	color: $fontColor;
}

.table-bordered {
	border: 1px solid $borderColor;
}

.tablecart {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		padding: 12px 15px;

		img {
			border: none;
			border-radius: $borderRadius;
			max-width: 47px;
			padding: 0;
		}
	}

	@include breakpoint(tablet) {
		ul {
			display: flex;
			flex-wrap: wrap;
		}

		li:nth-child(1) { width: 75px; }
		li:nth-child(2) { width: 40%; }
		li:nth-child(3) { width: calc(20% - 75px); }
		li:nth-child(4) { width: 20%; }
		li:nth-child(5) { width: 10%; }
		li:nth-child(6) { 
			text-align: right;
			width: 10%; 
			padding-right: 30px;
		}

		&.tablecart--checkout {
			li:nth-child(4) { width: 10%; }
			li:nth-child(5) { width: 20%; }
		}
	}
}


.tablecart__header {
	@include baseFont(600);
	background: $grayBg;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	color: $fontColor;
	display: none;

	@include breakpoint(tablet) {
		display: block;
	}
}

.tablecart__body {
	ul {
		border-bottom: 1px solid $borderColor;
	}

	.input-group {
		.fa {
			margin-right: 0;
		}
	}

	+ .tablecart__footer {
		border-top: none;
	}

	@include breakpoint(maxmobile) {
		ul {
			position: relative;
			li:first-child {
				position: absolute;
				top: 0;
				left: 0;
			}
			li:nth-child(2),
			li:nth-child(3) {
				padding-left: 80px;
				padding-bottom: 0;
			}

			li:nth-child(3) {
				padding-top: 0;
			}

			li:nth-child(5),
			li:nth-child(6) {
				position: relative;
				padding-left: 60%;
				padding-top: 0;
				text-align: right;

				&:before {
					@include baseFont(600);
					content: attr(data-name);
					color: $fontColor;
					text-align: left;
					position: absolute;
					top: 0;
					left: 15px;
					width: 60%;
				}
			}
		}
	}
}

.tablecart__footer {
	@include clearfix();
	background: $grayBg;
	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	margin-bottom: 24px;
	padding: 4px 0;

	li {
		float: left;
		padding: 4px 15px;

		strong {
			color: $fontColor;
		}

		&:nth-child(1) { 
			width: 60%; 
		}
		&:nth-child(2) { 
			text-align: right;
			width: 40%; 
		}
	}

	@include breakpoint(tablet) {
		li:nth-child(1) { 
			margin-left: 80%; 
			width: 10%; 
		}
		li:nth-child(2) { 
			width: 10%; 
			padding-right: 30px;
		}

		.tablecart--checkout & {
			li:nth-child(1) { 
				display: block;
				margin-left: 70%; 
				width: 20%; 
			}
			li:nth-child(2) { 
				text-align: right;
				width: 10%; 
				padding-right: 30px;
			}
		}
	}
}
