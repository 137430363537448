.panel-group {
	.panel-default {
		border-color: $borderColor;
		border-left: 0;
		border-right: 0;

		>.panel-heading {
			background: $grayBg;
			border-color: $borderColor;
			color: $fontColor;
			padding: 12px 15px;

			+.panel-collapse>.panel-body {
				border-color: $borderColor;
				border-left: 0;
				border-right: 0;
				padding: 15px 15px 30px;
			}
		}
	}

	.panel+.panel {
		margin-top: -1px;
	}

	.panel {
		border-radius: 0;
		box-shadow: none;
	}

	.panel-title {
		color: $fontColorGray;
		font-size: 14px;

		a {
			color: $fontColor;
			display: block;
			position: relative;
			padding-right: 20px;
			text-decoration: none !important;

			.fa {
				position: absolute;
				top: 3px;
				right: 0;
				font-size: 11px;
				transition: transform $transitionTime;
			}

			&[aria-expanded="true"] .fa {
				transform: rotate(-180deg);
			}
		}
	}
}

.panel.panel-contact {
	border: none;
	box-shadow: none;
}