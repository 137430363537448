.alert {
	position: relative;
	
	a {
		color: inherit;
		text-decoration: underline;
	}
	.close {
		position: absolute;
		top: 16px;
		right: 12px;
	}
}