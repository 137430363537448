.mfilter-box {
	.mfilter-content {
		border: none;
	}

	.mfilter-heading {
		background: #fff;

		.mfilter-heading-content {
			padding: 8px 0;
		}

		.mfilter-heading-text {
			color: $fontColor;
			font-size: 16px;
		}

		.mfilter-head-icon {
			background: none;
			color: $fontColor;
			transition: none;

			@include icon(before, f078) {
				display: block;
				font-size: 11px;
			}

			&:hover {
				color: $primaryColor;
			}
		}

		&.mfilter-collapsed .mfilter-head-icon:before {
			margin-top: -6px;
		}
	}

	.mfilter-content > ul > li.mfilter-filter-item {
		border-top: 1px solid $borderColor;
	}

	.mfilter-options .mfilter-disabled label {
		color: #ccc;
		cursor: default;
	}

	.mfilter-content-opts {
		padding-bottom: 20px;
	}

	.mfilter-opts-container {
		border: none;
		padding: 0;
	}

	.mfilter-tb-as-td {
		border: none;
	}

	.mfilter-col-input input[type=checkbox], .mfilter-col-input input[type=radio] {
		margin: -2px 6px 0 0;
	}

	.mfilter-filter-item .mfilter-options {
		padding: 0;
	}

	.mfilter-options {
		label {
			@include baseFont(400);
			color: $fontColor;
		}
		.mfilter-counter {
			background: $primaryColor;
			&:after { border-right-color: $primaryColor; }
		}
	}

	.mfilter-button-more {
		margin-top: 6px;
		padding: 10px 0 6px;
		font-size: 12px;
	}


	.mfilter-content-opts {
		position: relative;
		display: block;

		.mfilter-price {
			padding-bottom: 30px;
		}
		
		.mfilter-price-inputs {
			@include clearfix;
			position: absolute;
			bottom: 20px;
			color: #fff;
			width: 100%;

			input {
				padding: 0;
				background: transparent;
				border: none;
				width: auto;
				box-shadow: none;

				&:last-child {
					float: right;
					text-align: right;
				}
			}
		}
	}

	.mfilter-col-count {
		display: none;
	}

	.mfilter-button-bottom {
		padding: 10px 0 6px;
	}

	.mfilter-button-reset {
		display: block;
		margin-left: 0;

		i {
			background: none;
			height: auto;
			margin-right: 4px;
			width: auto;

			@include icon(before, f00d) {
				color: $error;
			}
		}
	}
}


.mfilter-free-container {
	background: #fff;
	border: none;
	border-right: 1px solid $borderColor;
	box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.15);
	height: calc(100% - 45px);
	padding-left: 0;
	margin-left: -228px;
	top: 45px;
	z-index: 101;

	.navigation--open & {
		opacity: 0;
	}

	&.mfilter-free-container-opened {
		&:before {
			opacity: 1;
			left: 0;
		}
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.25);
		position: fixed;
		opacity: 0;
		transition: opacity $transitionTime;
		top: 0;
		visibility: hidden;
		left: -100%;
		z-index: -1;
	}

	.mfilter-box {
		background: #fff;
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 10px;
	}

	.mfilter-free-button {
		background: $secondaryColor;
		border: none;
		width: 42px;
		height: 42px;
		color: #fff;
		line-height: 38px;
		display: block;
		text-align: center;
		font-size: 18px;
		margin-top: 0;

		@include icon(before, f0b0);
	}
}