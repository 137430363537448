.post {
	align-items: center;
	background-size: cover;
	background-position: center;
	background-color: $primaryColor;
	border-radius: $borderRadius;
	color: #fff;
	display: flex;
	justify-content: center;
	min-height: 275px;
	margin-bottom: 24px;
	text-align: center;
	padding: 20px;

	&__type {
		@include baseFont(700);
		@include fluidFont(14px, 16px);
		margin-bottom: 11px;
		text-shadow: 0px 0px 10px rgba(0,0,0,0.24);
		text-transform: uppercase;
	}

	&__title {
		@include fluidFont(18px, 24px);
		text-shadow: 0px 0px 10px rgba(0,0,0,0.24);
		margin-bottom: 24px;
	}

	.btn {
		min-width: 160px;
	}

	@include breakpoint(tablet) {
		min-height: 375px;
	}

	@include breakpoint(desktop) {
		min-height: 515px;
	}
}

@include breakpoint(maxmobile) {
	.post--slider {
		clear: both;
		margin-bottom: 24px;

		.slick-arrow {
			color: rgba(255,255,255,0.6);
			position: absolute;
			top: 50%;
			transition: color $transitionTime;
			transform: translateY(-50%);
			z-index: 1;

			&:hover { color: #fff; }
			i:before { 
				font-size: 20px; 
				text-shadow: 0px 0px 10px rgba(0,0,0,0.24);
			}

			&.slick-left { left: 20px; }
			&.slick-right { right: 20px; }
		}

		.col-sm-6 + .col-sm-6 {
			margin-top: 0;
		}

		.post {
			min-height: 350px;
			margin: 0;
		}
	}
} 