/*==================================
=            Pagination            =
==================================*/

.pagination-wrapper {
	margin-bottom: 24px; 

	@include breakpoint(tablet) {
		text-align: right;
	}

	.pagination>li>a {
		color: $primaryColor;
	}

	.pagination>li>span, 
	.pagination>li>a {
		border-color: $borderColor;
	}

	.pagination>li:first-child>a, 
	.pagination>li:first-child>span {
		border-top-left-radius: $borderRadius;
		border-bottom-left-radius: $borderRadius;
	}

	.pagination>li:last-child>a, 
	.pagination>li:last-child>span {
		border-top-right-radius: $borderRadius;
		border-bottom-right-radius: $borderRadius;
	}

	.pagination>.active>a, 
	.pagination>.active>a:focus, 
	.pagination>.active>a:hover, 
	.pagination>.active>span, 
	.pagination>.active>span:focus, 
	.pagination>.active>span:hover {
		background-color: $primaryColor;
		border-color: $primaryColor;
	}

	.pagination>li>a, 
	.pagination>li>span {
		@include baseFont(700);
		height: 40px;
		padding: 9px 12px;
		text-align: center;
		width: 40px;
	}

	.fa {
		font-size: 12px;
	}
}