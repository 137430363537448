.nav-tabs {
	position: relative;
	border-bottom: 1px solid $borderColor;

	// &:before {
	// 	content: '';
	// 	height: 1px;
	// 	background: $borderColor;
	// 	position: absolute;
	// 	bottom: -1px;
	// 	width: 4000px;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// }
}

.nav-tabs>li.active>a, 
.nav-tabs>li.active>a:focus, 
.nav-tabs>li.active>a:hover {
	border-color: transparent;
	border-top-color: transparent;
	border-radius: 0;
	box-shadow: inset 0px -2px 0px 0px $primaryColor;
}

.nav-tabs>li>a {
	color: $fontColorGray;

	&:hover {
		color: $urlColor;
		border-color: transparent transparent $borderColor;
	}
}

.nav>li>a:focus, 
.nav>li>a:hover {
	background: #fff;
}

.tab-pane {
	padding: 24px 0;
}