/*====================================================
=            Navigation trigger on mobile            =
====================================================*/
$navColor: #fff;

.navigation--trigger {
	background-color: transparent;
	cursor: pointer;
	display: block;
	padding: 10px 11px 0;
	position: relative;
	float: left;
	height: 45px;
	transition: all $transitionTime;
	text-transform: uppercase;
	vertical-align: middle;
	width: 46px;
	margin-left: -11px;
	z-index: 125;

	/*----------  Hamburger icon  ----------*/
	span {
		border-radius: $borderRadius;
		background-color: $navColor;
		display: inline-block;
		height: 3px;
		margin: 0;
		position: relative;
		transition: background-color 150ms;
		vertical-align: middle;
		width: 24px;


		&:before,
		&:after {
			background: $navColor;
			border-radius: $borderRadius;
			content: '';
			display: block;
			height: 3px;
			position: absolute;
			transition: top 150ms 250ms ease, transform 150ms ease, background-color 150ms;
			width: 24px;
		}

		&:before { top: -7px; }
		&:after { top: 7px; }
	}

	/* Animate the icon when open/close */
	.navigation--open & {
		span {
			background-color: transparent !important; // overwrite theming

			&:before {
				top: 0;
				transform: rotate3d(0,0,1,45deg);
				transition: top 150ms ease, transform 175ms 250ms ease, background-color 150ms;
			}

			&:after {
				top: 0;
				transform: rotate3d(0,0,1,-45deg);
				transition: top 150ms ease, transform 175ms 250ms ease, background-color 150ms;
			}
		}
	}
	
	@include breakpoint(tablet) {
		span {
			background-color: $primaryColor;
			&:before,
			&:after {
				background: $primaryColor;
			}
		}

		.navigation--open & {
			top: -55px;
			span {
				background-color: #fff;
				&:before,
				&:after {
					background: #fff;
				}
			}
		}
	}

	@include breakpoint(desktop) {
		display: none;
	}
}