.btn {
	border-radius: $borderRadius;
	font-size: 14px;
	font-weight: bold;
	text-shadow: none; 

	&,
	&:hover,
	&:focus,
	&:active:hover,
	&:active {
		outline: none;
		box-shadow: none; 
	}
	.fa {
		margin-right: 5px;
	}
}

.btn-primary {
	color: #fff;
	background-color: $secondaryColor;
	border-color: $secondaryColor;
	
	&.active,
	&:hover,
	&:focus,
	&:active:hover,
	&:active {
		background-color: $secondaryColorHover;
		border-color: $secondaryColorHover;
	}
}
.btn-primary.active.focus, 
.btn-primary.active:focus, 
.btn-primary.active:hover, 
.btn-primary:active.focus, 
.btn-primary:active:focus, 
.btn-primary:active:hover, 
.open>.dropdown-toggle.btn-primary.focus, 
.open>.dropdown-toggle.btn-primary:focus, 
.open>.dropdown-toggle.btn-primary:hover,
.open>.dropdown-toggle.btn-primary {
	background-color: $secondaryColorHover;
	border-color: $secondaryColorHover;
}

.btn-default {
	border-color: $borderColor;

	&.active,
	&:hover,
	&:focus,
	&:active:hover,
	&:active {
		background-color: $grayBg;
		border-color: $borderColor;
		color: $fontColorGray;
	}
}

.btn-white {
	color: $secondaryColor;
	background-color: #fff;
	border-color: #fff;

	&.active,
	&:hover,
	&:focus,
	&:active:hover,
	&:active  {
		background-color: $borderColor;
		border-color: $borderColor;
	}
}

.btn-input {
	color: $fontColor;
	background-color: $inputBg;
	border-color: $borderColor;

	&.active,
	&:hover,
	&:focus,
	&:active:hover,
	&:active  {
		background-color: $borderColor;
		border-color: $borderColor;
	}
}

.btn-group-lg>.btn, 
.btn-lg {
	padding: 11px 16px;
}

.btn-group-xl>.btn, 
.btn-xl {
	padding: 14px 16px;
}

.btn-sm {
	font-size: 12px;
	padding: 6px 20px;

	&.btn-icon {
		padding: 6px;
		width: 32px;
	}
}

.btn-group.open .dropdown-toggle {
	box-shadow: none;
}