#cart {
	display: none;
	float: right;

	.btn {
		position: relative;
		
		.total {
			position: absolute;
			background: $fontColor;
			width: 24px;
			height: 24px;
			right: -12px;
			top: -12px;
			border-radius: 50%;
			text-align: center;
			line-height: 24px;
		}
	}

	.dropdown-menu {
		min-width: 300px;
		padding: 0;

		table {
			margin-bottom: 0;
		}

		.cart-products a {
			color: $fontColor;
		}

		.btn-danger .fa {
			padding: 4px 1px;
			display: block;
			margin: 0;
		}

		img {
			min-height: 47px;
			min-width: 47px;
			border: none;
			border-radius: $borderRadius;
		}
	}

	.totals {
		background-color: $grayBg;
		color: $fontColor;
	}

	.actions {
		background-color: #fff;
		border-radius: 0 0 $borderRadius $borderRadius;
		padding: 8px;
		border-top: 1px solid $borderColor;
	}

	.empty-cart {
		padding: 8px;
	}

	@include breakpoint(desktop) {
		display: inline-block;
	}
}