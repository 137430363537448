.header {
	background: $primaryColor;
	border-bottom: 1px solid $borderColor;
	position: fixed;
	top: 0;
	left: 0;
	height: 87px;
	width: 100%;
	z-index: 99;

	.header--sticky & {
		position: fixed;
		top: 0;
	}

	.logo-mobile {
		float: left;
		margin: 11px 0 0 0;
		text-align: center;
		width: calc(100% - 60px);

		a {
			@include baseFont(700);
			font-size: 14px;
			color: #fff;
		}
	}

	.shopping-cart {
		color: #fff;
		float: right;
		font-size: 20px;
		margin-top: 7px;
		position: relative;

		.amount {
			@include baseFont(600);
			font-size: 10px;
			line-height: 18px;
			position: absolute;
			background: $fontColor;
			color: #fff;
			top: -4px;
			right: -14px;
			border-radius: 50%;
			width: 18px;
			height: 18px;
			text-align: center; 
		}
	}

	@include breakpoint(tablet) {
		background: #fff;
		height: 68px;
		padding: 12px 0;
		position: absolute;
		top: 42px;


		.logo {
			float: left;
			margin: 12px 0 0 6px;
			max-width: 250px;
		}

		.shopping-cart {
			background: $secondaryColor;
			width: 42px;
			height: 42px;
			margin: 0;
			border-radius: $borderRadius;
			text-align: center;
			line-height: 42px;

			.amount {
				width: 22px;
				height: 22px;
				font-size: 12px;
				line-height: 22px;
				top: -8px;
				right: -14px;
			}
		}
	}

	@include breakpoint(desktop) {
		padding: 17px 0 0;
		height: calc(160px - 42px);

		.shopping-cart {
			display: none;
		}
	}
}

body {
	padding-top: 87px;

	@include breakpoint(tablet) {
		padding-top: 110px;
	}

	@include breakpoint(desktop) {
		padding-top: 160px;
	}
}

.mobile-header-info {
	text-align: center;
	padding: 5px;
	color: $primaryColor;
  	display: none;

	@include breakpoint(maxmobile) {
		display: block;
	}
}