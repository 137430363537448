.header .search {
	float: left;
	margin: 0 -15px;
	padding: 0;
	width: calc(100% + 30px);

	p {
		display: none;
	}

	input {
		border-radius: 0;
		background-color: #fff;
		font-size: 14px;
		height: 42px;
	}

	.btn {
		background: $fontColor;
		border-color: $fontColor;
		border-radius: 0;
		color: #fff;
		font-size: 20px;
		height: 42px;
		padding: 0 6px 0 11px;

		.fa {
			display: inline-block;
			margin-top: -2px;
		}
		
		&:focus,
		&.active,
		&:active,
		&:hover {
			border-color: $primaryColor;
			background: $primaryColor;
		}
	}
	
	@include breakpoint(maxmobile) {
		.has-filter & {
			padding-left: 42px;
		}
	}

	@include breakpoint(tablet) {
		float: right;
		margin-right: 18px;
		width: 350px;

		&.input-group input.form-control {
			border-radius: $borderRadius 0 0 $borderRadius;
		}

		.btn {
			border-radius: 0 $borderRadius $borderRadius 0;
		}
	}

	@include breakpoint(desktop) {
		max-width: 500px;
		width: 100%;

		.form-control {
			max-width: 300px;
			vertical-align: top;
			float: left;
		}

		.input-group-btn {
			vertical-align: top;
			padding: 0;
			float: left;
		}

		p {
			color: $fontColor;
			display: inline-block;
			float: left;
			margin-right: 28px;
			text-align: right;

			a {
				@include baseFont(600);
				color: $secondaryColor;

				&:hover {
					color: $secondaryColor;
					text-decoration: none;
				}
			}
		}
	}
}