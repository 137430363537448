/*===========================================
=            White footer bottom            =
===========================================*/

.footer__bottom {
	@include baseFont(500);
	background: #fff;
	color: $fontColorGray;
	padding: 20px 0 32px;
	text-align: center;

	a {
		color: $fontColorGray;
		&:hover { color: $urlColor; }
	}

	@include breakpoint(desktop) {
		padding-bottom: 60px;
	}


	@include breakpoint(maxmobile) {
		.footer__meta li { display: block; }
	}
}


/*----------  Footer logo`s  ----------*/
.footer__icons {
	margin-bottom: 16px;
	li {
		padding: 5px;
	}

	@include breakpoint(tablet) {
		li {
			padding: 12px;
		}
	}
}

/*----------  Footer url`s  ----------*/
.footer__meta ul {
	margin-bottom: 6px;

	li {
		padding: 0 7px;
	}
}


/*----------  Feedback company  ----------*/
.feedback-company a {
	@include baseFont(700);
	color: $secondaryColor;
	&:hover {
		color: $secondaryColor;
		text-decoration: underline;
	}
}