.top {
	background: $primaryColor;
	color: #fff;			
	padding: 12px 0;
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 42px;
	overflow: hidden;

	.list-inline {
		margin: 0;
	}

	a {
		color: #fff;
	}

	#top-usp li {
		padding-right: 12px;
	}

	@include breakpoint(tablet) {
		display: block;
		font-size: 12px;
	}

	@include breakpoint(desktop) {
		#top-usp {
			max-width: 70%;
			overflow: hidden;
			max-height: 20px;
		}
	}

	@include breakpoint(desktop-m) {
		padding: 10px 0;
		font-size: 14px;

		#top-usp li {
			padding-right: 20px;
		}
	}
}