/*==================================
=            Typography            =
==================================*/
*:focus { outline: none; }
/*----------  Headings  ----------*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	@include baseFont(700);
	color: $fontColor;		
	line-height: 1.15;
	margin-top: 0;

	span {
		@include baseFont(400);
	}
}

h1, .h1 {
	// @include fluidFont(28px, 38px);
	@include fluidFont(18px, 24px);

	&.title {
		margin: 0 0 28px;
		text-align: left;
	}
}

h2, .h2 {
	@include fluidFont(18px, 24px);
}

h3, .h3 {
	font-size: 22px;
}

h4, .h4 {
	font-size: 18px;
}

h5, .h5 {
	font-size: 16px;
}

h6, .h6 {
	font-size: 16px;
}

.title {
	margin: 12px 0 24px;
	float: left;
	line-height: 1.3;
	text-align: center;
	width: 100%;

	@include breakpoint(tablet) {
		line-height: 1.15;
	}
}

/*----------  Paragraphs  ----------*/
strong {
	@include baseFont(700);
}

p {
	margin-top: 0;
	font-size: 14px;
	line-height: 1.5;

	&.aligncenter { text-align: center; }
	&.alignleft { text-align: left; }
	&.alignright { text-align: right; }

	&.success {
		color: $succes;
	}
	&.error {
		color: $error;
	}
	&.notice {
		color: $notice;
	}
}

p { 
	margin: 0 0 18px;

	&:last-child {
		margin-bottom: 0;
	}
}

.list-styled {
	padding: 0;
	list-style: none;

	li {
		padding-left: 12px;
		position: relative;
		padding: 2px 0 2px 12px;

		@include icon(before, 'f054') {
			font-size: 8px;
			left: 0;
			position: absolute;
			top: 8px;
		}
	}

	@include breakpoint(tablet) {
		li {
			padding: 4px 0 4px 12px;
			&:before {
				top: 9px;
			}
		}

	}
}

.list-dot {
	@include clearfix;
	list-style: none;
	margin-bottom: 24px;
	padding: 0;

	li {
		display: block;
		float: left;
		margin: 4px 0;
		position: relative;
		padding-left: 24px;
		width: 100%;

		&:before {
			content: '';
			background: $fontColor;
			border-radius: 50%;
			height: 9px;
			left: 0;
			position: absolute;
			top: 7px;
			width: 9px;
		}
	}
}

.list-border {
	padding: 0;
	list-style: none;

	li {
		padding: 8px 0;
		position: relative;
		border-bottom: 1px solid $borderColor;

		strong {
			color: $fontColor;
			display: inline-block;
			padding-right: 8px;
			width: 150px;
		}

		span {
			display: inline-block;
			width: calc(100% - 150px);
		}

		&:last-child {
			border-bottom: none;
		}
	}
}

img:focus {
	outline: none;
	outline-offset: 0;
}

a {
	@include transition(color, $transitionTime);
	color: $urlColor;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: $urlColorHover;
		text-decoration: none;
	}

	&:focus {
		outline: none;
		outline-offset: 0;
	}
}