.search-filter {
	@include clearfix();
	background: $grayBg;
	border: 1px solid $borderColor;
	border-radius: $borderRadius;
	margin-bottom: 24px;
	padding: 15px;

	select, input[type="text"] {
		background: #fff;
	}

	.btn {
		margin-top: 24px;
	}

	@include breakpoint(tablet) {
		padding: 15px 30px 20px;

		.btn {
			margin-top: 0;
		}
	}
}