.introduction {
	color: $fontColorGray;
	margin-bottom: 35px;
	line-height: 1.7;

	.title {
		margin-top: 0;
		text-align: left;
	}
}

.category-filter {

	.list-unstyled {
		margin: 0 auto 20px;

		li {
			display: inline-block;

			&:after {
				content: '|';
				margin: 0 4px 0 8px;
				font-size: 11px;
				vertical-align: middle;
			}

			&:last-child:after {
				display: none;
			}

			a {
				color: $primaryColor;
				&:hover {
					color: $fontColor;
				}
			}
		}

		strong {
			color: $fontColor;
		}
	}

	.form-group {
		width: 100%;
	}
	.form-control {
		height: 42px;
		padding: 8px 12px;
	}

	@include breakpoint(tablet) {
		.list-unstyled {
			margin: 14px auto 0;
		}
	}
	@include breakpoint(desktop) {
		.form-group {
			width: auto;
			float: right;
		}
	}
}